// @ts-check
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MenuItem, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { setSurveyListings } from '~/legacy/store/actions/viewSurvey'
import { ConfirmModalComponent, DeleteIcon } from '~/legacy/components'
import { BASE_ICON_STYLES } from './ButtonUtils'

const useStyles = makeStyles((theme) => ({
  ...BASE_ICON_STYLES(theme),
}))

const RemoveSpaceFromSurveyMenuItem = React.forwardRef(
  ({ handleMenuClose, label, ...props }, ref) => {
    const classes = useStyles()

    return (
      <MenuItem
        ref={ref}
        className={classes.menuItemRoot}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          handleMenuClose(e)
        }}
        {...props}
      >
        <DeleteIcon className={classes.icon} />
        <Typography className={classes.text}>{label}</Typography>
      </MenuItem>
    )
  }
)

const ConfirmRemoveSpaceFromSurveyModal = ({
  open,
  closeModal,
  onConfirm,
  spaceName,
  buildingName,
}) => (
  <ConfirmModalComponent
    ModalComponentProps={{
      open: !!open,
      onClose: closeModal,
    }}
    onClose={closeModal}
    onConfirm={onConfirm}
    title="Remove Space"
    confirmButtonLabel="Delete"
    text={`Are you sure you want to remove ${
      spaceName || 'this space'
    } from ${buildingName}? This action can’t be undone.`}
  />
)

export const useRemoveSpaceFromSurveyMenuItem = ({
  surveyListingId,
  spaceName,
  buildingName,
  handleMenuClose,
  deleteSurveyListing,
  onClickOverride = undefined,
  label = 'Remove Space',
  ...props
}) => {
  const [open, setOpen] = React.useState(false)
  const dispatch = useDispatch()
  const surveyListings = useSelector(
    (store) => store.pages.viewSurvey.surveyListings
  )

  const RemoveSpaceFromSurveyMenuItemComponent = (
    <RemoveSpaceFromSurveyMenuItem
      onClick={(event) => {
        event.preventDefault()
        event.stopPropagation()
        if (onClickOverride) {
          onClickOverride(event)
        } else {
          setOpen(true)
          handleMenuClose()
        }
      }}
      handleMenuClose={handleMenuClose}
      label={label}
      {...props}
    />
  )

  const ConfirmRemoveSpaceFromSurveyModalComponent = (
    <ConfirmRemoveSpaceFromSurveyModal
      open={open}
      closeModal={() => setOpen(false)}
      onConfirm={() => {
        deleteSurveyListing(surveyListingId)
        dispatch(
          setSurveyListings(
            surveyListings.filter((sl) => sl.id !== surveyListingId)
          )
        )
        setOpen(false)
      }}
      spaceName={spaceName}
      buildingName={buildingName}
    />
  )

  return {
    RemoveSpaceFromSurveyMenuItemComponent,
    ConfirmRemoveSpaceFromSurveyModalComponent,
  }
}
