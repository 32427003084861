// @ts-check
import React, { useState } from 'react'
import merge from 'lodash/merge'
import { BULK_IMPORT_CONSTANTS } from '~/legacy/utils'
import { EditableCell, TableContentCell, TableRow } from '../../tableComponents'
import { DragIcon } from '~/legacy/components/svgs'

export const AddCustomFieldTableRow = ({
  objectIds,
  createCustomFields,
  editableCellClasses,
  isSpaces = false,
}) => {
  const [name, setName] = useState('')
  const [valueMap, setValueMap] = useState(
    merge(...objectIds.map((id) => ({ [id]: '' })))
  )

  const handleFieldCreate = (name) => {
    if (name) {
      createCustomFields({ name, valueMap })
      setName('')
    }
  }

  const updateValueMap = (newData) =>
    setValueMap({
      ...valueMap,
      ...newData,
    })
  const IconClass = BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.STRING.icon

  return (
    <TableRow data-id="add-custom-field-row">
      <TableContentCell
        classes={{
          tableCell: `pl-2 pr-3 border-0 border-solid border-[#E0E0E0] border-l border-r border-b ${
            isSpaces ? 'sticky left-8 z-[1] bg-white' : ''
          }`,
        }}
      >
        <div className="inline-flex h-14 w-full items-center justify-center">
          <div className="flex pr-1.5">
            <DragIcon className="opacity-50" />
          </div>
          <div className="p-[2px] flex items-center">
            <IconClass className="opacity-50" />
          </div>
          <EditableCell
            value={name}
            updateValueApi={(newValue) =>
              new Promise(async (resolve) => {
                const value = newValue.trim()
                setName(value)
                const data = await handleFieldCreate(newValue)
                resolve(data)
              })
            }
            classesIn={editableCellClasses}
            styleOverrides={{ hoverBackgroundColor: '#f3f3f3' }}
            textContentVariant="bodyBold"
            placeholder="Add Name"
          />
        </div>
      </TableContentCell>
      {objectIds.map((id) => (
        <TableContentCell
          key={id}
          classes={{
            tableCell:
              'px-3 border-0 border-solid border-[#E0E0E0] border-r border-b',
          }}
        >
          <EditableCell
            value={valueMap[id]}
            updateValue={(newValue) => updateValueMap({ [id]: newValue })}
            updateValueApi={(newValue) =>
              new Promise((resolve) => {
                updateValueMap({ [id]: newValue })
                resolve(newValue)
              })
            }
            classesIn={editableCellClasses}
            styleOverrides={{ hoverBackgroundColor: '#f3f3f3' }}
          />
        </TableContentCell>
      ))}
    </TableRow>
  )
}
