// @ts-check
import React, { useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import {
  CompareSurveyScalingImageContainer,
  ScalingBuildingCard,
} from '~/legacy/components/surveyListingCardGrid/SurveyListingCardGrid'
import {
  getViewBuildingRoute,
  getViewListingRoute,
  useSurveyInBdpBuildout,
} from '~/legacy/utils'
import { openLink } from '~/legacy/utils/openLink'

// TODO: Break down this component
const CompareListingCard = ({
  listing,
  survey,
  index,
  moveListingCard,
  removeListing,
}) => {
  const isUserInBuildout = useSurveyInBdpBuildout(survey)
  const [isHovered, setIsHovered] = useState(false)
  const ref = useRef(null)
  const previewRef = useRef(null)
  const ItemTypes = {
    CARD: 'card',
  }

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: ItemTypes.CARD, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      moveListingCard(dragIndex, hoverIndex)
      // eslint-disable-next-line
      item.index = hoverIndex
    },
  })
  const opacity = isDragging ? 0 : 1
  drag(ref)
  drop(previewRef)
  preview(previewRef)

  return (
    <div ref={previewRef} style={{ opacity }} data-handler-id={handlerId}>
      <ScalingBuildingCard
        gridItemKey={listing.id}
        cardXs={12 / 3}
        listing={listing}
        isBuildingCard={false}
        building={listing.building}
        spacesAvailable={0}
        setIsHovered={setIsHovered}
        includeSpaceDetails={false}
        onHoverEnter={() => {}}
        onHoverExit={() => {}}
        onClick={openLink(
          isUserInBuildout
            ? getViewBuildingRoute(survey.id, listing.building.id)
            : getViewListingRoute(listing.id, survey.id)
        )}
        ImageContainerComponent={
          <CompareSurveyScalingImageContainer
            listing={listing}
            isHovered={isHovered}
            ref={ref}
            removeListing={removeListing}
          />
        }
      />
    </div>
  )
}

export default CompareListingCard
