import React from 'react'
import { Tabs, Typography, CloseIcon } from '~/legacy/components'

export default function FullScreenTitle({
  title,
  onClose,
  tabLabels = [],
  selectedTab,
  setSelectedTab,
}) {
  return (
    <div className="w-full min-h-[60px] max-h-[60px] py-0 px-8 flex justify-between items-center fixed bg-white z-2 border-0 border-b border-solid border-[#e0e0e0]">
      <Typography className="flex grow basis-[0%]" variant="bodyBold">
        {title}
      </Typography>
      {tabLabels.length > 0 && (
        <Tabs
          className="h-[60px]"
          classes={{ tabRoot: 'h-[60px]' }}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabLabels={tabLabels}
        />
      )}
      <div className="flex grow basis-[0%]">
        <CloseIcon className="ml-auto cursor-pointer" onClick={onClose} />
      </div>
    </div>
  )
}
