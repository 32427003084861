import { BUILDING_INTEREST_LOOKUP } from '~/legacy/consts'
import { formatId, SEGMENT_PARAM_NAMES } from '~/legacy/utils'
import { fetchApi } from './rootApi'

const BUILDING_ENDPOINT_ROOT = 'buildings/'

export const buildingApi = {
  updatePartial: async ({ buildingId, partial }) =>
    fetchApi({
      endpoint: `${BUILDING_ENDPOINT_ROOT}${buildingId}/`,
      fetchArgs: {
        method: 'PATCH',
        body: JSON.stringify(partial),
      },
      trackingArgs: {
        name: 'Update Building Partial',
        metadata: {
          [SEGMENT_PARAM_NAMES.BUILDING_ID]: formatId(buildingId),
        },
      },
    }),

  // TODO: also do we even call this anymore?
  updateInterest: async ({ buildingId, surveyId, newInterest }) =>
    fetchApi({
      endpoint: `${BUILDING_ENDPOINT_ROOT}${buildingId}/update_interest/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify({ survey_id: surveyId, interest: newInterest }),
      },
      trackingArgs: {
        name: 'Update Survey Building Interest',
        metadata: {
          [SEGMENT_PARAM_NAMES.BUILDING_ID]: formatId(buildingId),
          [SEGMENT_PARAM_NAMES.SURVEY_ID]: formatId(surveyId),
          [SEGMENT_PARAM_NAMES.SURVEY_BUILDING_INTEREST]:
            BUILDING_INTEREST_LOOKUP[newInterest],
        },
      },
    }),
}
