// @ts-check
import { Fade, Grid, Typography, useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { setSurveyBuildings } from '~/legacy/store/actions/viewSurvey'
import {
  CardImage,
  CloseIcon,
  DeclineListingModalReal,
  DragIcon,
  getSurveyListingCardScrollToId,
  LargeChevronLeftIcon,
  LargeChevronRightIcon,
  MoreIcon,
  NotInterestedIconButton,
  PricePerSqftIcon,
  ScheduleTourIconButton,
  SqftIcon,
  SquareIconButton,
  STICKY_BAR_HEIGHT_PX,
  SurveyListingMenu,
  SpaceIcon,
  SdpSurveyBuildingMenu,
  FavoriteBuildingButton,
  NotInterestedBuildingButton,
  updateSurveyBuildingInterestSdp,
  Loading,
} from '~/legacy/components'
import ImportListingsSection from '~/legacy/components/ImportListingsSection'
import {
  getPlural,
  formatSizeByListing,
  formatSqftPriceByListing,
  getBuildingTertiaryName,
  getListingCardName,
  getBuildingCardName,
  getListingImagesForDisplay,
  getBuildlingImagesForDisplay,
  getStaticImage,
  getViewListingRoute,
  getViewBuildingRoute,
  isBroker,
  getListingCompareImagesForDisplay,
} from '~/legacy/utils'
import { BUILDING_INTEREST } from '~/legacy/consts'
import { FEATURE_FLAGS } from '~/legacy/utils/featureFlags'

const IMAGE_BORDER_PX = 1
const BORDER_RADIUS_PX = 4
const useStyles = makeStyles({
  noPadding: {
    padding: 0,
    '&:first-child': {
      padding: 0,
    },
  },
  item: {
    minWidth: '240px', // need to account for padding here
    maxWidth: '550px', // need to account for padding here
    cursor: 'pointer',
  },
  itemContent: {
    border: `${IMAGE_BORDER_PX}px solid #D8D8D8`,
    width: '100%',
    height: '100%',
    borderRadius: `${BORDER_RADIUS_PX}px`,
    '& img, & .missingBuildingImage': {
      borderRadius: `${BORDER_RADIUS_PX - IMAGE_BORDER_PX}px ${
        BORDER_RADIUS_PX - IMAGE_BORDER_PX
      }px 0 0`,
    },
  },
  root: {
    flexGrow: 1,
    height: (props) => (props.fullHeightRoot ? '100%' : 'fit-content'),
  },
  textContainer: {
    padding: '16px',
  },
  icon: {
    marginRight: '4px',
    color: '#111',
  },
  spaceDetailItem: {
    display: 'flex',
    margin: 'auto',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  data: {
    margin: 'auto 0',
  },
  ellipsisOverflow: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  loadingContainer: {
    width: '100%',
    display: 'flex',
  },
  loading: {
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
    marginTop: '40px',
    display: 'flex',
  },
})

const useScalingImageStyles = makeStyles({
  scalingImageContainer: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    margin: 'auto',
    zIndex: 2,
  },
  scrim: {
    background: 'rgba(0, 0, 0, .05)',
    zIndex: 1,
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
  },
  image: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  missingBuildingImage: {
    display: 'flex',
    margin: 'auto',
  },
  iconLeft: {
    marginRight: 'auto',
  },
  iconRight: {
    marginLeft: 'auto',
  },
  iconTopRight: {
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    padding: '8px',
  },
  iconTopLeft: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '8px',
  },
  arrowsContainer: {
    zIndex: 2,
    display: 'flex',
  },
  icon: {
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    color: '#FFF',
    '& svg': {
      zIndex: 2,
      filter: 'drop-shadow(0px 0px 2px #000000)',
    },
  },
  dragButton: {
    cursor: 'grab',
  },
  overlayItem: {
    zIndex: 4,
  },
  overlayButton: {
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.15) !important',
    },
  },
  buttonOpacity: {
    opacity: '40% !important',
  },
  darkHoverBackground: {
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.4) !important',
    },
  },
  hidden: {
    display: 'none',
  },
  clickableIconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '49px',
    height: '100%',
    display: 'flex',
    zIndex: 3,
  },
  closeIcon: {
    color: '#FFFFFF',
  },
})

const CompareListingCardImageOverlay = React.forwardRef(
  ({ removeListing }, dragRef) => {
    const classes = useScalingImageStyles()
    return (
      <>
        <div className={clsx(classes.icon, classes.iconTopLeft)}>
          <div
            ref={dragRef}
            className={clsx(classes.overlayItem, classes.dragButton)}
          >
            <DragIcon
              className={classes.closeIcon}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
            />
          </div>
        </div>
        <div className={clsx(classes.icon, classes.iconTopRight)}>
          <SquareIconButton
            className={clsx(classes.overlayButton, classes.overlayItem)}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              removeListing()
            }}
          >
            <CloseIcon className={classes.closeIcon} />
          </SquareIconButton>
        </div>
      </>
    )
  }
)

const SurveyListingCardImageOverlay = ({
  isUserInBuildout = false,
  images,
  surveyListing,
  surveyBuilding,
  allSurveyListings,
  allSurveyBuildings,
  user,
  currentImageIndex,
  setCurrentImageIndex,
  setSurveyListingToDecline,
  isHovered = false,
}) => {
  const classes = useScalingImageStyles()
  const dispatch = useDispatch()

  const [anchorMenuEl, setAnchorMenuEl] = useState(null)

  // Handle popover menu open/close
  const handleMenuClick = (event) => {
    setAnchorMenuEl(event.currentTarget)
  }
  const handleMenuClose = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    setAnchorMenuEl(null)
  }

  useEffect(() => {
    if (!isHovered) {
      handleMenuClose()
    }
  }, [isHovered])

  const getNextImageIndex = (currentImageIndexLocal) => {
    return currentImageIndexLocal + 1 >= images.length
      ? 0
      : currentImageIndexLocal + 1
  }
  const getPreviousImageIndex = (currentImageIndexLocal) => {
    return currentImageIndexLocal - 1 < 0
      ? images.length - 1
      : currentImageIndexLocal - 1
  }
  const showNextImage = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setCurrentImageIndex(getNextImageIndex(currentImageIndex))
  }
  const showPreviousImage = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setCurrentImageIndex(getPreviousImageIndex(currentImageIndex))
  }

  // Get indecies of next/previous images to preload to prevent lag
  const nextImageIndex = getNextImageIndex(currentImageIndex)
  const previousImageIndex = getPreviousImageIndex(currentImageIndex)
  const nextImage = images[nextImageIndex]
  const previousImage = images[previousImageIndex]

  let isFavorited = false
  let isNotInterested = false
  if (isUserInBuildout) {
    isFavorited =
      surveyBuilding && surveyBuilding.interest === BUILDING_INTEREST.FAVORITED
    isNotInterested =
      !isFavorited &&
      surveyBuilding &&
      surveyBuilding.interest === BUILDING_INTEREST.NOT_INTERESTED
  }
  const theme = useTheme()
  const [loadingInterest, setLoadingInterest] = useState(false)
  const updateInterest = async (event, newInterest) =>
    updateSurveyBuildingInterestSdp(
      event,
      newInterest,
      surveyBuilding,
      allSurveyBuildings,
      dispatch,
      setSurveyBuildings,
      setLoadingInterest,
      theme
    )

  return (
    <>
      {!!isHovered && (
        <div className={clsx(classes.icon, classes.arrowsContainer)}>
          <div
            className={clsx(classes.clickableIconContainer, classes.iconLeft)}
            onClick={showPreviousImage}
          >
            <LargeChevronLeftIcon />
          </div>
          <div
            className={clsx(classes.clickableIconContainer, classes.iconRight)}
            onClick={showNextImage}
          >
            <LargeChevronRightIcon />
          </div>
        </div>
      )}
      {!user.isAnonymous && (
        <div className={clsx(classes.icon, classes.iconTopRight)}>
          {isBroker(user) ? (
            <>
              <SquareIconButton
                className={clsx(classes.overlayButton, classes.overlayItem)}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleMenuClick(e)
                }}
              >
                <MoreIcon />
              </SquareIconButton>
              {isUserInBuildout ? (
                <SdpSurveyBuildingMenu
                  building={surveyBuilding.building}
                  surveyId={surveyBuilding.survey.id}
                  handleMenuClose={handleMenuClose}
                  id="more-options-menu"
                  anchorEl={anchorMenuEl}
                  open={!!anchorMenuEl}
                  onClose={handleMenuClose}
                  elevation={2}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  getContentAnchorEl={null}
                />
              ) : (
                <SurveyListingMenu
                  listingId={surveyListing.listing.id}
                  surveyId={surveyListing.survey.id}
                  surveyListing={surveyListing}
                  handleMenuClose={handleMenuClose}
                  id="more-options-menu"
                  anchorEl={anchorMenuEl}
                  open={!!anchorMenuEl}
                  onClose={handleMenuClose}
                  elevation={2}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  getContentAnchorEl={null}
                  isBdpBuildout={isUserInBuildout}
                />
              )}
            </>
          ) : (
            <>
              {isUserInBuildout
                ? [
                  <div style={{ paddingRight: '4px' }} key="schedule">
                    <FavoriteBuildingButton
                      classes={{
                          iconButton: clsx(
                            classes.overlayButton,
                            classes.overlayItem
                          ),
                        }}
                      disabled={loadingInterest}
                      TooltipProps={{ enterDelay: 2000 }}
                      isFavorited={isFavorited}
                      isNotInterested={isNotInterested}
                      key="favorite-icon"
                      onClick={(event) =>
                          updateInterest(
                            event,
                            isFavorited
                              ? BUILDING_INTEREST.NEUTRAL
                              : BUILDING_INTEREST.FAVORITED
                          )
                        }
                    />
                  </div>,
                  <NotInterestedBuildingButton
                    classes={{
                        iconButton: clsx(
                          classes.overlayButton,
                          classes.overlayItem
                        ),
                      }}
                    disabled={loadingInterest}
                    TooltipProps={{ enterDelay: 2000 }}
                    isNotInterested={isNotInterested}
                    isFavorited={isFavorited}
                    key="not-interested-icon"
                    onClick={(event) =>
                        updateInterest(
                          event,
                          isNotInterested
                            ? BUILDING_INTEREST.NEUTRAL
                            : BUILDING_INTEREST.NOT_INTERESTED
                        )
                      }
                  />,
                  ]
                : [
                  <div style={{ paddingRight: '4px' }} key="schedule">
                    <ScheduleTourIconButton
                      surveyListing={surveyListing}
                      allSurveyListings={allSurveyListings}
                      SquareIconButtonProps={{
                          containedIconClasses: classes.buttonOpacity,
                          className: clsx(
                            classes.overlayButton,
                            classes.overlayItem
                          ),
                        }}
                    />
                  </div>,
                  <NotInterestedIconButton
                    key="not-interested"
                    surveyListing={surveyListing}
                    setSurveyListingToDecline={setSurveyListingToDecline}
                    SquareIconButtonProps={{
                        className: clsx(
                          classes.overlayButton,
                          classes.overlayItem
                        ),
                      }}
                  />,
                  ]}
            </>
          )}
        </div>
      )}
      {/* Preload the next/previous image to prevent lag */}
      {nextImageIndex !== currentImageIndex && (
        <div className={classes.hidden}>
          <CardImage
            imageFile={nextImage}
            ImgProps={{ alt: '', className: classes.image }}
          />
        </div>
      )}
      {previousImageIndex !== currentImageIndex &&
        previousImageIndex !== nextImageIndex && (
          <div className={classes.hidden}>
            <CardImage
              imageFile={previousImage}
              ImgProps={{
                alt: '',
                className: classes.image,
              }}
            />
          </div>
        )}
    </>
  )
}

export const CompareSurveyScalingImageContainer = React.forwardRef(
  ({ listing, isHovered, removeListing }, dragRef) => {
    const images = getListingCompareImagesForDisplay(listing)
    if (!images || !images.length) {
      images.push(getStaticImage('PlaceholderBuildingPhotoCardUI.png'))
    }

    const image = images[0]
    if (!image || !image.url) {
      return ''
    }

    return (
      <ScalingImageContainer
        image={image}
        isHovered={isHovered}
        imageAspectRatio="56.25%"
        OverlayComponent={
          <CompareListingCardImageOverlay
            ref={dragRef}
            listing={listing}
            removeListing={removeListing}
          />
        }
      />
    )
  }
)

export const SurveyListingScalingImageContainer = ({
  isUserInBuildout = false,
  surveyListing,
  surveyBuilding,
  allSurveyListings,
  allSurveyBuildings,
  setSurveyListingToDecline,
  isHovered = false,
}) => {
  const user = useSelector((s) => s.user)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const images = isUserInBuildout
    ? getBuildlingImagesForDisplay(surveyBuilding.building)
    : getListingImagesForDisplay(surveyListing.listing)
  if (!images || !images.length) {
    images.push(getStaticImage('PlaceholderBuildingPhotoCardUI.png'))
  }

  const image = images[currentImageIndex]
  if (!image || !image.url) {
    return ''
  }

  return (
    <ScalingImageContainer
      image={image}
      isHovered={isHovered}
      OverlayComponent={
        <SurveyListingCardImageOverlay
          isUserInBuildout={isUserInBuildout}
          images={images}
          surveyListing={surveyListing}
          surveyBuilding={surveyBuilding}
          allSurveyListings={allSurveyListings}
          allSurveyBuildings={allSurveyBuildings}
          user={user}
          currentImageIndex={currentImageIndex}
          setCurrentImageIndex={setCurrentImageIndex}
          setSurveyListingToDecline={setSurveyListingToDecline}
          isHovered={isHovered}
        />
      }
    />
  )
}

const ScalingImageContainer = ({
  image,
  isHovered = false,
  imageAspectRatio = '66.66%',
  OverlayComponent,
}) => {
  const classes = useScalingImageStyles()

  return (
    <div
      className={classes.scalingImageContainer}
      style={{ paddingTop: imageAspectRatio }}
    >
      {OverlayComponent}
      <Fade in={!!isHovered}>
        <div className={classes.scrim} />
      </Fade>
      <CardImage
        imageFile={image}
        ImgProps={{ alt: '', className: classes.image }}
      />
    </div>
  )
}

export const ScalingBuildingCard = ({
  gridItemKey,
  cardXs,
  listing,
  building,
  isBuildingCard = false,
  spacesAvailable,
  setIsHovered,
  includeSpaceDetails = true,
  onHoverEnter = () => {},
  onHoverExit = () => {},
  onClick = () => {},
  ImageContainerComponent,
  isActive = true,
}) => {
  const classes = useStyles()

  // Handle tracking mouse entering/leaving the card.
  const handleMouseEnter = () => {
    onHoverEnter()
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    onHoverExit()
    setIsHovered(false)
  }

  const missingDetailsPlaceholder = '--'
  const formattedSize =
    formatSizeByListing(listing) || missingDetailsPlaceholder
  let formattedPrice = formatSqftPriceByListing(listing)
  if (formattedPrice) {
    formattedPrice += ' psf'
  } else {
    formattedPrice = missingDetailsPlaceholder
  }

  const name = isBuildingCard
    ? getBuildingCardName(building)
    : getListingCardName(listing)

  return (
    <Grid
      item
      key={gridItemKey}
      xs={cardXs}
      className={classes.item}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ opacity: !isActive ? '50%' : '' }}
      onClick={(event) => {
        if (isActive) {
          event.stopPropagation()
          event.preventDefault()
        }
      }}
    >
      <div
        id={getSurveyListingCardScrollToId(listing.id)}
        style={{
          position: 'relative',
          top: `-${STICKY_BAR_HEIGHT_PX + 8}px`,
          left: 0,
        }}
      />
      <Grid container className={classes.itemContent} onClick={onClick}>
        <Grid item xs={12}>
          {ImageContainerComponent}
        </Grid>
        <Grid item xs={12}>
          {/* Everything below image */}
          <Grid
            container
            direction="column"
            spacing={2}
            className={classes.textContainer}
          >
            {/* Address details */}
            <Grid item xs={12}>
              <Grid container direction="column" wrap="nowrap">
                {name && (
                  <Grid item>
                    <Typography variant="h3" noWrap>
                      {name}
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  <Typography variant="body1" noWrap>
                    {building.address}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" noWrap>
                    {getBuildingTertiaryName(building)}
                  </Typography>
                </Grid>
                {!name && (
                  <Grid item>
                    <Typography variant="h3" noWrap>
                      &nbsp;
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>

            {/* Space Details Row */}
            {!!includeSpaceDetails && (
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  justify="flex-start"
                  alignItems="flex-start"
                  wrap="nowrap"
                >
                  {isBuildingCard ? (
                    <Grid item className={classes.ellipsisOverflow}>
                      <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        className={classes.ellipsisOverflow}
                      >
                        <SpaceIcon className={classes.spaceIcon} />
                        <Typography
                          variant="h3"
                          noWrap
                          className={clsx(classes.text, classes.numSpacesText)}
                        >
                          {`${getPlural(spacesAvailable, 'Space')} Available`}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    [
                      // Size
                      <Grid
                        item
                        className={classes.ellipsisOverflow}
                        key="size"
                      >
                        <Grid
                          container
                          direction="row"
                          wrap="nowrap"
                          className={classes.ellipsisOverflow}
                        >
                          <Grid item className={classes.spaceDetailItem}>
                            <SqftIcon className={classes.icon} />
                          </Grid>
                          <Grid item className={classes.spaceDetailItem}>
                            <Typography variant="h3" noWrap>
                              {formattedSize}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>,

                      // Price
                      <Grid
                        item
                        className={classes.ellipsisOverflow}
                        key="price"
                      >
                        <Grid
                          container
                          direction="row"
                          wrap="nowrap"
                          className={classes.ellipsisOverflow}
                        >
                          <Grid item className={classes.spaceDetailItem}>
                            <PricePerSqftIcon className={classes.icon} />
                          </Grid>
                          <Grid item className={classes.spaceDetailItem}>
                            <Typography variant="h3" noWrap>
                              {formattedPrice}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>,
                    ]
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const SurveyListingCard = ({
  surveyListing,
  surveyBuilding,
  isUserInBuildout = false,
  allSurveyListings,
  allSurveyBuildings,
  setSurveyListingToDecline,
  isCondensed,
  setHoveredBuildingId,
  numItems,
  buildingId,
  spacesAvailable,
  isActive = true,
}) => {
  const history = useHistory()
  const theme = useTheme()

  const [isHovered, setIsHovered] = useState(false)

  // Get desired number of columns for a Grid size. Either desired number or min number of survery listings so it looks better
  const getColNum = (numColumns) => {
    return Math.ceil(12 / Math.min(numColumns, numItems))
  }
  // We set the number
  const ROUGH_IDEAL_CARD_WIDTH_PX = 280
  const largeOrUp5 = useMediaQuery(
    theme.breakpoints.up(
      theme.breakpoints.values.lg + ROUGH_IDEAL_CARD_WIDTH_PX * 5
    )
  )
  const largeOrUp3 = useMediaQuery(
    theme.breakpoints.up(
      theme.breakpoints.values.lg + ROUGH_IDEAL_CARD_WIDTH_PX * 3
    )
  )
  const largeOrUp2 = useMediaQuery(
    theme.breakpoints.up(
      theme.breakpoints.values.lg + ROUGH_IDEAL_CARD_WIDTH_PX * 2
    )
  )
  const largeOrUP = useMediaQuery(theme.breakpoints.up('lg'))
  const mediumOrUP = useMediaQuery(theme.breakpoints.up('md'))
  const customMediumOrUP = useMediaQuery(theme.breakpoints.up(680))
  const smallOrUP = useMediaQuery(theme.breakpoints.up('sm'))
  let numCardColumns
  if (isCondensed) {
    if (largeOrUp5) {
      numCardColumns = getColNum(6)
    } else if (largeOrUp3) {
      numCardColumns = getColNum(4)
    } else if (largeOrUP) {
      numCardColumns = getColNum(3)
    } else if (customMediumOrUP) {
      numCardColumns = getColNum(2)
    } else {
      numCardColumns = getColNum(1)
    }
  } else if (largeOrUp2) {
    numCardColumns = getColNum(6)
  } else if (largeOrUP) {
    numCardColumns = getColNum(4)
  } else if (mediumOrUP) {
    numCardColumns = getColNum(3)
  } else if (smallOrUP) {
    numCardColumns = getColNum(2)
  } else {
    numCardColumns = getColNum(1)
  }

  const onHoverEnter = () => setHoveredBuildingId(buildingId)
  const onHoverExit = () => {
    setHoveredBuildingId(false)
  }

  const onClick = () => {
    history.push(
      isUserInBuildout
        ? getViewBuildingRoute(
            surveyBuilding.survey.id,
            surveyBuilding.building.id
          )
        : getViewListingRoute(surveyListing.listing.id, surveyListing.survey.id)
    )
  }

  return (
    <ScalingBuildingCard
      gridItemKey={isUserInBuildout ? surveyBuilding.uuid : surveyListing.id}
      cardXs={numCardColumns}
      listing={isUserInBuildout ? {} : surveyListing.listing}
      building={
        isUserInBuildout
          ? surveyBuilding.building
          : surveyListing.listing.building
      }
      isBuildingCard={isUserInBuildout}
      spacesAvailable={spacesAvailable}
      setIsHovered={setIsHovered}
      onHoverEnter={onHoverEnter}
      onHoverExit={onHoverExit}
      onClick={onClick}
      isActive={isActive}
      ImageContainerComponent={
        <SurveyListingScalingImageContainer
          isUserInBuildout={isUserInBuildout}
          surveyListing={surveyListing}
          surveyBuilding={surveyBuilding}
          allSurveyListings={allSurveyListings}
          allSurveyBuildings={allSurveyBuildings}
          isHovered={isHovered}
          setSurveyListingToDecline={setSurveyListingToDecline}
        />
      }
    />
  )
}

export function SurveyListingCardGrid({
  isUserInBuildout,
  surveyListings,
  surveyBuildingsMap,
  setSurveyBuildingsMap,
  isCondensed,
  setHoveredBuildingId,
  isLoading,
  setLoadedListingsFromCsv,
  setRawCsvHeaders,
  setBulkImportFieldMatchModalOpen,
  setBackdropOpen,
  setUploadedCsvFile,
  activeMapMarker,
  noSurveyBuildings = false,
}) {
  const classes = useStyles({
    fullHeightRoot: setSurveyBuildingsMap.length === 0 && !isLoading,
  })
  const [surveyListingToDecline, setSurveyListingToDecline] = useState(null)
  const ingestPdfEnabled = useFeatureFlagEnabled(FEATURE_FLAGS.INGEST_PDF)

  // Scroll to the active map marker
  useEffect(() => {
    if (activeMapMarker) {
      let firstItemToDisplay
      if (isUserInBuildout) {
        firstItemToDisplay = surveyBuildingsMap.find(
          (surveyBuilding) =>
            surveyBuilding.building.id === activeMapMarker.buildingId
        )
      } else {
        firstItemToDisplay = surveyListings.find(
          (surveyListing) =>
            surveyListing.listing.building.id === activeMapMarker.buildingId
        )
      }
      if (firstItemToDisplay) {
        const elementId = getSurveyListingCardScrollToId(
          isUserInBuildout
            ? firstItemToDisplay.building.id
            : firstItemToDisplay.listing.id
        )
        const el = document.getElementById(elementId)
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      }
    }
  }, [activeMapMarker, surveyBuildingsMap, surveyListings, isUserInBuildout])

  if (isLoading) {
    return (
      <div className={classes.root}>
        <div className={classes.loadingContainer}>
          <Loading isLoading size={40} className={classes.loading} />
        </div>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      {((!isUserInBuildout && surveyListings.length === 0) ||
        (isUserInBuildout &&
          noSurveyBuildings &&
          surveyBuildingsMap.length === 0)) && (
          <>
            {ingestPdfEnabled ? (
              <ImportListingsSection
                setBackdropOpen={setBackdropOpen}
                setLoadedListingsFromCsv={setLoadedListingsFromCsv}
                setRawCsvHeaders={setRawCsvHeaders}
                setBulkImportFieldMatchModalOpen={
                setBulkImportFieldMatchModalOpen
              }
                setUploadedCsvFile={setUploadedCsvFile}
              />
          ) : (
            <ImportListingsSection
              isBdpBuildout={isUserInBuildout}
              setBackdropOpen={setBackdropOpen}
              setLoadedListingsFromCsv={setLoadedListingsFromCsv}
              setRawCsvHeaders={setRawCsvHeaders}
              setBulkImportFieldMatchModalOpen={
                setBulkImportFieldMatchModalOpen
              }
              setUploadedCsvFile={setUploadedCsvFile}
            />
          )}
          </>
      )}
      {!isUserInBuildout && surveyListings.length > 0 && (
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          style={{ flexGrow: 1 }}
        >
          {surveyListings.map((surveyListing) => (
            <SurveyListingCard
              surveyListing={surveyListing}
              buildingId={surveyListing.listing.building.id}
              isUserInBuildout={isUserInBuildout}
              allSurveyListings={surveyListings}
              isCondensed={isCondensed}
              key={surveyListing.id}
              numItems={surveyListings ? surveyListings.length : 0}
              setHoveredBuildingId={setHoveredBuildingId}
              setSurveyListingToDecline={setSurveyListingToDecline}
              // Either there isn't an active marker, or there is and this survey listing is in that active building (ie: marker clicked)
              isActive={
                !!(
                  !activeMapMarker ||
                  (activeMapMarker &&
                    activeMapMarker.buildingId ===
                      surveyListing.listing.building.id)
                )
              }
            />
          ))}
        </Grid>
      )}
      {isUserInBuildout && surveyBuildingsMap.length > 0 && (
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          style={{ flexGrow: 1 }}
        >
          {surveyBuildingsMap.map((surveyBuilding) => (
            <SurveyListingCard
              surveyBuilding={surveyBuilding}
              spacesAvailable={
                surveyBuilding ? surveyBuilding.spaces_available : 0
              }
              buildingId={surveyBuilding.building.id}
              isUserInBuildout={isUserInBuildout}
              allSurveyBuildings={surveyBuildingsMap}
              isCondensed={isCondensed}
              key={surveyBuilding.uuid}
              numItems={surveyBuildingsMap ? surveyBuildingsMap.length : 0}
              setHoveredBuildingId={setHoveredBuildingId}
              // Either there isn't an active marker, or there is and this survey building is in that active building (ie: marker clicked)
              isActive={
                !!(
                  !activeMapMarker ||
                  (activeMapMarker &&
                    activeMapMarker.buildingId === surveyBuilding.building.id)
                )
              }
            />
          ))}
        </Grid>
      )}
      <DeclineListingModalReal
        surveyListingToDecline={surveyListingToDecline}
        allSurveyListings={surveyListings}
        setSurveyListingToDecline={setSurveyListingToDecline}
      />
    </div>
  )
}
