// @ts-check
import {
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from '@material-ui/core'
import debounce from 'lodash/debounce'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import Api from 'rest-fetcher-redux'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import {
  AddressAutocomplete,
  Amenities,
  Button,
  Modal,
  ModalComponent,
  MODALS,
  SquareIconButton,
  TextInput,
  Typography,
  useEditSpaceMenu,
} from '~/legacy/components'
import { useFieldMenu } from '~/legacy/components/menus/EditFieldMenu'
import {
  DragIcon,
  LogoIcon,
  TextChevronDown,
  TextChevronUp,
} from '~/legacy/components/svgs'
import {
  EditableCell,
  TableContentCell,
  TableHeaderCell,
  TableHeaderRow,
  TableHeaderText,
  TableRow,
} from '~/legacy/components/tableComponents'
import { EditLocationForm } from '~/legacy/pages/Surveys/Survey/EditLocationForm'
import {
  arrayMove,
  BACKEND_FOR_DND,
  BULK_IMPORT_CONSTANTS,
  getBuildingPrimaryName,
  getIconByDataTypeId,
  getModelFieldNameByDisplayName,
  getPlaceholderTextByDisplayName,
  getPlural,
  useSurveyBuildingSelector,
  useWindowDimensions,
} from '~/legacy/utils'
import { useStupidRefresh } from '~/support/useStupidRefresh'
import FullScreenTitle from './FullScreenTitle'
import { AddCustomFieldTableRow } from './shared/AddCustomFieldTableRow'
import { useSurveyCustomFieldsSelector } from '~/legacy/utils/hooks/useSurveyCustomFieldsSelector'
import { useSurveyListingCustomFieldSelector } from '~/legacy/utils/hooks/useSurveyListingCustomFieldSelector'
import { useAddSpaceToBuildingMenu } from '~/legacy/components/menus/AddSpaceToBuildingMenu'

export default function EditBuildingModal({
  surveyId,
  surveyName,
  surveyBuilding,
  building,
  mutate,
  updateBuilding,
  ModalComponentProps,
  customFieldValues,
  createCustomFieldValue,
  updateCustomFieldValue,
  refreshValues,
}) {
  const classes = useStyles()

  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const mainContentRef = useRef(null)

  const tabOptions = ['Overview', 'Building', 'Spaces', 'Location']
  const selectedTabParam = queryParams.get('edit') || 'Overview'
  const selectedTab = tabOptions.indexOf(selectedTabParam)

  // store building state locally for a few values, so we can debounce updates
  const [buildingData, setBuildingData] = useState(building)
  const buildingPrimaryName = useMemo(
    () => getBuildingPrimaryName(buildingData),
    [buildingData]
  )

  const {
    buildingCustomFields: customFieldDefinitions,
    mutateChangeCustomFieldType: changeCustomFieldDataType,
    mutateChangeCustomFieldLabel: renameCustomField,
    mutateDeleteCustomField: deleteCustomField,
    mutateCreateCustomField: createCustomField,
    mutateSetCustomFieldOrder: setFieldOrder,
  } = useSurveyCustomFieldsSelector({ surveyId, enabled: Boolean(surveyId) })

  const [showAddFromAnotherModal, setShowAddFromAnotherModal] = useState(false)
  const [orderedCustomFields, setOrderedCustomFields] = useState(
    customFieldDefinitions
  )

  const uniqueFieldNames = new Set(
    customFieldDefinitions
      ? customFieldDefinitions.map((item) => item.label)
      : []
  )

  const valuesMap = {}
  customFieldValues.forEach((item) => {
    valuesMap[item.custom_field_id] = item
  })

  const lastOrder =
    customFieldDefinitions && customFieldDefinitions.length > 0
      ? customFieldDefinitions[customFieldDefinitions.length - 1].order
      : undefined

  useEffect(() => {
    setOrderedCustomFields(customFieldDefinitions)
  }, [customFieldDefinitions])

  const debouncedUpdateBuilding = useCallback(
    debounce(
      (newData, updateBuildingLocal) => updateBuildingLocal(newData),
      1000
    ),
    []
  )

  const updateBuildingData = (newData) => {
    setBuildingData({ ...buildingData, ...newData })
    debouncedUpdateBuilding(newData, updateBuilding)
  }

  const {
    setAnchorMenuEl: setFieldMenuAnchorEl,
    SpaceFieldMenuComponent: FieldMenuComponent,
    DeleteSpaceFieldModalComponent: DeleteFieldModalComponent,
    ConfirmChangeFieldTypeModalComponent,
  } = useFieldMenu({
    surveyName,
    isBuildingField: true,
    deleteField: ({ modelFieldName, fieldId }) => {
      if (modelFieldName) {
        // Delete a regular field
        return updateBuilding({ [modelFieldName]: null })
      }
      // Delete a custom field
      return deleteCustomField({ id: fieldId })
    },
    changeCustomFieldDataType,
    refreshValues,
  })

  const moveCustomField = useCallback(
    (dragIndex, hoverIndex) => {
      setOrderedCustomFields((a) => arrayMove(a, dragIndex, hoverIndex))
    },
    [setOrderedCustomFields]
  )

  const saveCustomFieldOrder = useCallback(async () => {
    const localNewFieldOrder = {}
    orderedCustomFields.forEach((cf, index) => {
      localNewFieldOrder[cf.id] = index
    })
    setFieldOrder({ fieldOrder: localNewFieldOrder })
  }, [setFieldOrder, orderedCustomFields])

  useStupidRefresh()

  // Make sure we reset the scroll when changing tabs
  useEffect(() => {
    if (mainContentRef.current) {
      mainContentRef.current.scrollTo(0, 0)
    }
  }, [location.search])

  return (
    <ModalComponent
      classesIn={{ dialogContent: 'w-screen overflow-hidden first:p-0' }}
      fullScreen
      {...ModalComponentProps}
    >
      <FullScreenTitle
        title={buildingPrimaryName}
        onClose={ModalComponentProps.onClose}
        tabLabels={tabOptions}
        selectedTab={selectedTab}
        setSelectedTab={(selectedTabIndex) => {
          history.replace({
            pathname: location.pathname,
            search: `?edit=${tabOptions[selectedTabIndex]}`,
          })
        }}
      />
      <DndProvider backend={BACKEND_FOR_DND}>
        <div
          className="w-full mt-[60px] pt-[70px] overflow-auto flex flex-col"
          ref={mainContentRef}
        >
          {selectedTabParam === 'Overview' && (
            <OverviewTab
              building={building}
              buildingData={buildingData}
              setBuildingData={setBuildingData}
              updateBuildingData={updateBuildingData}
              updateBuilding={updateBuilding}
            />
          )}

          {selectedTabParam === 'Building' && (
            <div className="mb-10 w-[640px] mx-auto">
              <Typography
                className="pb-8 w-full border-0 border-b border-solid border-[#e0e0e0]"
                variant="pageTitle"
              >
                Building
              </Typography>

              <div className="flex justify-between items-center w-full py-4 px-0">
                <Typography variant="boldText">
                  {getPlural(customFieldDefinitions.length, 'Field')}
                </Typography>
                <Button
                  className="min-w-0 h-9 normal-case"
                  classes={{ endIcon: 'ml-0' }}
                  color="primary"
                  onClick={() => {
                    document
                      .querySelector('[data-id="add-custom-field-row"]')
                      .scrollIntoView()
                  }}
                >
                  Add a Field
                </Button>
              </div>

              <TableContainer>
                <Table size="small" className={classes.metadataTable}>
                  <colgroup>
                    <col style={{ width: 'calc(50% - 16px)' }} />
                    <col style={{ width: 'calc(50% - 16px)' }} />
                  </colgroup>
                  <TableHead>
                    <TableHeaderRow>
                      <TableHeaderCell
                        classes={{
                          tableCell:
                            'border-0 border-solid border-[#E0E0E0] border-l border-t border-b border-r',
                        }}
                      >
                        <TableHeaderText>FIELD NAME</TableHeaderText>
                      </TableHeaderCell>
                      <TableHeaderCell
                        classes={{
                          tableCell:
                            'border-0 border-solid border-[#E0E0E0] border-t border-b border-r',
                        }}
                      >
                        <TableHeaderText>FIELD VALUE</TableHeaderText>
                      </TableHeaderCell>
                    </TableHeaderRow>
                  </TableHead>
                  <TableBody>
                    {orderedCustomFields.map((customField, index) => (
                      <CustomFieldRow
                        key={customField.label}
                        index={index}
                        fieldData={customField}
                        valueData={valuesMap[customField.id]}
                        surveyId={surveyId}
                        building={building}
                        setFieldMenuAnchorEl={setFieldMenuAnchorEl}
                        renameCustomField={renameCustomField}
                        createCustomFieldValue={createCustomFieldValue}
                        updateCustomFieldValue={updateCustomFieldValue}
                        uniqueFieldNames={uniqueFieldNames}
                        moveField={moveCustomField}
                        saveFieldOrder={saveCustomFieldOrder}
                      />
                    ))}

                    <AddCustomFieldTableRow
                      objectIds={[building.id]}
                      editableCellClasses={editableCellStyles()}
                      createCustomFields={(newCustomFieldData) => {
                        if (
                          newCustomFieldData.name &&
                          !uniqueFieldNames.has(newCustomFieldData.name)
                        ) {
                          createCustomField({
                            label: newCustomFieldData.name,
                            order: lastOrder ? lastOrder + 1 : undefined,
                          })
                        }
                      }}
                    />
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}

          {selectedTabParam === 'Spaces' && <SpacesTab />}

          {selectedTabParam === 'Location' && (
            <div className="w-[640px] mx-auto">
              <Typography
                className="pb-8 w-full border-0 border-b border-solid border-[#e0e0e0]"
                variant="pageTitle"
              >
                Location
              </Typography>

              <EditLocationForm
                building={buildingData}
                padding="30px 0px"
                onChange={updateBuildingData}
              />
            </div>
          )}
        </div>
      </DndProvider>

      <Modal
        content={MODALS.ADD_FIELDS_FROM_ANOTHER}
        onClose={() => setShowAddFromAnotherModal(false)}
        open={showAddFromAnotherModal}
        childProps={{
          // this modal handles updating the building on the backend,
          // mutate just refreshes the local SWR data
          updateCustomFields: (updatedBuildings) =>
            mutate({
              ...surveyBuilding,
              building: { ...updatedBuildings[0] },
            }),
          parentObjectIds: [building.id],
          surveyId,
          type: 'building',
        }}
      />
      {FieldMenuComponent}
      {DeleteFieldModalComponent}
      {ConfirmChangeFieldTypeModalComponent}
    </ModalComponent>
  )
}

// FIXME: For now I'm passing all the props as they where.
// But I'm sure this could be improved to avoid repeating and passing data that should be derived
function OverviewTab({
  building,
  buildingData,
  setBuildingData,
  updateBuildingData,
  updateBuilding,
}) {
  // TODO: do we need this?
  const [newAddressValues, setNewAddressValues] = useState(building)
  const [addressErrorText, setAddressErrorText] = useState('')

  return (
    <div className="w-[640px] mx-auto">
      <Typography
        className="pb-8 w-full border-0 border-b border-solid border-[#e0e0e0]"
        variant="pageTitle"
      >
        Overview
      </Typography>

      <div className="grid gap-7 py-10 px-0">
        <AddressAutocomplete
          autoFocus={false}
          label="Address"
          error={!!addressErrorText}
          helperText={addressErrorText}
          fetchFreshBuildingData={false}
          controlledValue={newAddressValues}
          onBlur={() => {
            if (!newAddressValues.address) {
              setNewAddressValues(building)
            }
          }}
          setAddressValues={(newValues) => {
            setAddressErrorText('')
            setNewAddressValues(newValues)
            if (newValues.address) {
              Api.updateBuildingAddress({
                id: building.id,
                body: {
                  ...newValues,
                },
              }).then((res) => {
                if (res.data.error) {
                  setAddressErrorText(res.data.error)
                } else {
                  setBuildingData({ ...buildingData, ...res.data })
                  updateBuilding({ ...res.data })
                }
              })
            }
          }}
        />
        <TextInput
          label="Building Name"
          onChange={(e) => updateBuildingData({ name: e.target.value })}
          value={buildingData.name}
        />
        <TextInput
          label="Notes"
          multiline
          rows={6}
          onChange={(e) => updateBuildingData({ description: e.target.value })}
          value={buildingData.description}
        />
        <Amenities
          amenities={buildingData.amenities}
          updateAmenities={(newAmenities) => {
            updateBuildingData({ amenities: newAmenities })
          }}
        />
      </div>
    </div>
  )
}

function SpacesTab() {
  const [showAddFromAnotherModal, setShowAddFromAnotherModal] = useState(false)
  const [addingListing, setAddingListing] = useState(false)
  const [isSticky, setIsSticky] = useState(false)
  const stickySectionRef = React.createRef()

  const { survey_id: surveyId, building_id: buildingId } = useParams()
  const classes = useStyles()
  const isAnonymous = useSelector((s) => s.user.isAnonymous)

  const reservedFields = BULK_IMPORT_CONSTANTS.NEW_RESERVED_FIELDS.filter(
    (field) => field.fieldType.id === BULK_IMPORT_CONSTANTS.FIELD_TYPES.SPACE.id
  ).map((field) => ({
    ...field,
    name: field.displayName,
    label: field.displayName,
    data_type: field.fieldDataType.id,
    dataType: field.fieldDataType,
  }))

  const {
    survey,
    listingCustomFields: customFieldDefinitions,
    mutate: refreshCustomFields,
    mutateChangeCustomFieldType: changeCustomFieldDataType,
    mutateChangeCustomFieldLabel: renameCustomField,
    mutateDeleteCustomField: deleteCustomField,
    mutateCreateCustomField: createCustomField,
    mutateSetCustomFieldOrder: setFieldOrder,
  } = useSurveyCustomFieldsSelector({ surveyId, enabled: Boolean(surveyId) })

  const {
    customFieldValues,
    mutate: refreshValues,
    mutateCreateListingCustomFieldValue: createCustomFieldValue,
    mutateChangeListingCustomFieldValue: updateCustomFieldValue,
  } = useSurveyListingCustomFieldSelector({
    surveyId,
    buildingId,
  })

  const {
    surveyBuilding,
    mutate: refreshSurveyBuilding,
    mutateDeleteSurveyListing: deleteSurveyListing,
    mutateUpdateListing: updateListing,
    mutateClearSpacesField: clearSpacesField,
    mutateCreateSurveyListing: createSurveyListing,
    mutateCopySpacesFromSurvey: copySpacesFromSurvey,
  } = useSurveyBuildingSelector(surveyId, buildingId, isAnonymous)

  const [orderedCustomFields, setOrderedCustomFields] = useState(
    customFieldDefinitions
  )

  const uniqueFieldNames = new Set(
    customFieldDefinitions
      ? customFieldDefinitions.map((item) => item.label)
      : []
  )

  const lastOrder =
    customFieldDefinitions && customFieldDefinitions.length > 0
      ? customFieldDefinitions[customFieldDefinitions.length - 1].order
      : undefined

  useEffect(() => {
    setOrderedCustomFields(customFieldDefinitions)
  }, [customFieldDefinitions])

  const surveySpaces = surveyBuilding.survey_listings || []
  const dividerColumns = [-1].concat(surveySpaces.map((ss) => ss.id))
  const spaceIds = surveySpaces.map((s) => s.listing.id)
  const buildingName = getBuildingPrimaryName(surveyBuilding.building)

  const { windowWidth } = useWindowDimensions()

  const pagePadding = useMemo(() => {
    // FIXME: Make this variables, we are using it in other palces
    // COL_WIDTH = 220
    // MIN_TABLE_WIDTH = 640
    const tableWidth = Math.max((surveySpaces.length + 1) * 220 + 2, 640)

    // Substracting 16px (8 on each side) to prevent horizontal scroll when not needed
    const pagePadding = (windowWidth - tableWidth) / 2 - 8

    return Math.max(pagePadding, 32)
  }, [surveySpaces.length, windowWidth])

  const reservedFieldValues = surveySpaces.reduce(
    (acc, ss) => {
      const { address2: name, description } = ss.listing

      return {
        'Space Name': [
          ...acc['Space Name'],
          {
            id: 'name',
            value: name,
            formattedValue: name,
            surveyListingId: ss.id,
          },
        ],
        'Space Notes': [
          ...acc['Space Notes'],
          {
            id: 'notes',
            value: description,
            formattedValue: description,
            surveyListingId: ss.id,
          },
        ],
      }
    },
    {
      'Space Name': [],
      'Space Notes': [],
    }
  )

  const rowsByFieldName = orderedCustomFields.map((cf) => {
    const values = []

    surveySpaces.forEach((surveySpace) => {
      const value = customFieldValues.find(
        (cfv) =>
          cfv.custom_field_id === cf.id &&
          cfv.listing_id === surveySpace.listing.id
      ) ?? { value: null }
      values.push(value)
    })

    return {
      field: cf,
      values,
    }
  })

  const {
    setAnchorMenuEl,
    AddSpaceToBuildingMenuComponent,
    AddExistingSpacesToBuildingModalComponent,
  } = useAddSpaceToBuildingMenu({
    addNewSpace: async (surveyBuildingId) => {
      setAddingListing(true)
      try {
        await createSurveyListing({ surveyBuildingId })
      } catch {
        // supress
      } finally {
        setAddingListing(false)
      }
    },
    copySpacesFromSurvey: async ({ copySpacesFromSurveyId }) => {
      await copySpacesFromSurvey({ copySpacesFromSurveyId })
      refreshValues()
      refreshCustomFields()
    },
  })

  const {
    setAnchorMenuEl: setFieldMenuAnchorEl,
    SpaceFieldMenuComponent,
    DeleteSpaceFieldModalComponent,
    ConfirmChangeFieldTypeModalComponent,
  } = useFieldMenu({
    surveyName: survey?.name,
    isBuildingField: false,
    deleteField: ({ modelFieldName, fieldId }) => {
      if (modelFieldName) {
        // Delete a regular field
        return clearSpacesField(modelFieldName)
      }
      // Delete a custom field
      return deleteCustomField({ id: fieldId })
    },
    changeCustomFieldDataType,
    refreshValues,
  })

  const saveFieldOrder = useCallback(async () => {
    const localNewFieldOrder = {}
    orderedCustomFields.forEach((cf, index) => {
      localNewFieldOrder[cf.id] = index
    })
    setFieldOrder({
      fieldOrder: localNewFieldOrder,
      isBuildingFields: false,
    })
  }, [setFieldOrder, orderedCustomFields])

  // Move the field on drag, local order
  const moveField = useCallback(
    (dragIndex, hoverIndex) => {
      setOrderedCustomFields((a) => arrayMove(a, dragIndex, hoverIndex))
    },
    [setOrderedCustomFields]
  )

  useEffect(() => {
    const cachedRef = stickySectionRef.current
    if (!cachedRef) {
      return () => {}
    }
    const observer = new IntersectionObserver(
      ([e]) => {
        // FIXME: This depends on the element being -1px to the top of the normal position (in this case -71px instead of -70px)
        // We could find another solution by putting the reference in an element we know is moving outside the viewport
        // For example the element inmediatly obove the one we want to stick
        setIsSticky(e.intersectionRatio < 1)
      },
      { threshold: [1] }
    )
    observer.observe(cachedRef)

    return () => observer.unobserve(cachedRef)
  }, [stickySectionRef])

  return (
    <>
      <div
        style={{ padding: `0 ${pagePadding}px` }}
        className="w-full sticky left-0 top-[-120px]"
      >
        <Typography
          className="pb-8 w-full border-0 border-b border-solid border-[#e0e0e0]"
          variant="pageTitle"
        >
          Spaces
        </Typography>
      </div>

      <div
        className="flex justify-between items-center w-full py-4 sticky left-0 top-[-71px] bg-white z-[5]"
        style={{ padding: `0 ${pagePadding}px` }}
        ref={stickySectionRef}
      >
        <Typography variant="boldText">
          {getPlural(customFieldDefinitions.length, 'Field')}
        </Typography>
        <div className="flex gap-2 items-center">
          <Button
            color="primary"
            className="min-w-[unset] h-9 py-[11px] px-4 normal-case"
            disabled={addingListing}
            loading={addingListing}
            onClick={async (event) => {
              setAnchorMenuEl({
                anchor: event.currentTarget,
                surveyBuilding,
                surveyId: parseInt(surveyId, 10),
              })
            }}
          >
            Add a Space
          </Button>

          <Button
            className="min-w-[unset] h-9 py-[11px] px-4 normal-case"
            color="primary"
            onClick={() => {
              document
                .querySelector('[data-id="add-custom-field-row"]')
                .scrollIntoView()
            }}
          >
            Add a Field
          </Button>
        </div>

        {/* FIXME: Div to cover the table content passing behing the sticky headers */}
        <div
          className={clsx(
            'w-full absolute h-[18px] bg-white left-0 right-0 top-[69px] z-[-1]',
            isSticky ? 'block' : 'hidden'
          )}
        />
      </div>

      {/* Sticky border */}
      <div className="w-full h-[1px] min-h-[1px] sticky left-0 top-[-3px] bg-[#E0E0E0] z-[3]" />

      {/* Sticky border hider */}
      <div className="w-full h-[1px] min-h-[1px] sticky left-0 bg-white z-[4] mt-[-1px]" />

      {surveySpaces.length > 0 && (
        <div className="w-full">
          {/*
            This is a hack to avoid showing the scrollable content behind the sticky column
            It creates a vertical bar that covers the space between the left side of the page and the table
          */}
          <div className="absolute top-[129px] left-0 bottom-2 w-8 bg-white z-10" />

          <TableContainer
            className={clsx('overflow-visible w-fit mb-20')}
            style={{ padding: `0 ${pagePadding}px` }}
          >
            <Table size="small" className={classes.metadataTable}>
              <TableHead className="sticky top-4 z-[3]">
                <TableHeaderRow>
                  <TableHeaderCell
                    classes={{
                      tableCell: clsx(
                        'sticky top-0 left-8 border-solid border-[#E0E0E0] border',
                        isSticky && 'shadow-b'
                      ),
                    }}
                  >
                    <TableHeaderText>FIELD NAME</TableHeaderText>
                  </TableHeaderCell>
                  {surveySpaces.map((surveySpace) => (
                    <SpaceHeader
                      key={surveySpace.listing.id}
                      surveyBuilding={surveyBuilding}
                      buildingName="Test Name"
                      space={surveySpace.listing}
                      updateSpaceName={(newName) =>
                        updateListing(surveySpace.listing.id, {
                          address2: newName,
                        })
                      }
                      deleteSurveyListing={deleteSurveyListing}
                      isSticky={isSticky}
                    />
                  ))}
                </TableHeaderRow>
              </TableHead>
              <TableBody>
                {/* Reserved fields */}
                {reservedFields.map((field) => (
                  <SpaceTableRow
                    key={field.displayName}
                    label={field.displayName}
                    index={field.displayName}
                    surveyId={surveyId}
                    field={field}
                    values={reservedFieldValues[field.displayName]}
                    surveySpaces={surveySpaces}
                    updateListing={updateListing}
                    createCustomFieldValue={createCustomFieldValue}
                    updateCustomFieldValue={updateCustomFieldValue}
                    renameCustomField={renameCustomField}
                    setFieldMenuAnchorEl={setFieldMenuAnchorEl}
                    buildingName={buildingName}
                    moveField={moveField}
                    saveFieldOrder={saveFieldOrder}
                    uniqueFieldNames={uniqueFieldNames}
                  />
                ))}

                {/* Divider */}
                <TableRow classes={{ row: 'h-6' }}>
                  {dividerColumns.map((key) => (
                    <TableContentCell
                      classes={{
                        tableCell:
                          'p-0 bg-[#F9F9F9] first:sticky first:left-8 first:z-[1] border-0 border-solid border-[#E0E0E0] first:border-l border-r border-b',
                      }}
                      key={key}
                    />
                  ))}
                </TableRow>

                {/* Custom fields */}
                {rowsByFieldName.map(({ field, values }, index) => (
                  <SpaceTableRow
                    index={index}
                    surveyId={surveyId}
                    key={field.label}
                    label={field.label}
                    field={field}
                    values={values}
                    surveySpaces={surveySpaces}
                    updateListing={updateListing}
                    createCustomFieldValue={createCustomFieldValue}
                    updateCustomFieldValue={updateCustomFieldValue}
                    renameCustomField={renameCustomField}
                    setFieldMenuAnchorEl={setFieldMenuAnchorEl}
                    buildingName={buildingName}
                    moveField={moveField}
                    saveFieldOrder={saveFieldOrder}
                    uniqueFieldNames={uniqueFieldNames}
                  />
                ))}

                <AddCustomFieldTableRow
                  objectIds={spaceIds}
                  editableCellClasses={editableCellStyles()}
                  createCustomFields={(newCustomFieldData) => {
                    if (
                      newCustomFieldData.name &&
                      !uniqueFieldNames.has(newCustomFieldData.name)
                    ) {
                      createCustomField({
                        label: newCustomFieldData.name,
                        order: lastOrder ? lastOrder + 1 : undefined,
                        isBuildingField: false,
                      })
                    }
                  }}
                  isSpaces
                />
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      {surveySpaces.length === 0 && (
        <div style={{ padding: `0 ${pagePadding}px` }}>
          <div className="bg-[#e0e0e0] text-[#666666] flex flex-col justify-center items-center rounded py-[30px] w-[640px]">
            <Typography className="mb-1" variant="emptyStateHeader">
              Add a Space to This Building
            </Typography>
            <Typography variant="bodyText">
              Tailor what you show with custom fields that reflect your Tenant’s
              needs.
            </Typography>
          </div>
        </div>
      )}

      {AddSpaceToBuildingMenuComponent}
      {AddExistingSpacesToBuildingModalComponent}
      <Modal
        content={MODALS.ADD_FIELDS_FROM_ANOTHER}
        onClose={() => setShowAddFromAnotherModal(false)}
        open={showAddFromAnotherModal}
        childProps={{
          // this modal handles updating the building on the backend,
          // mutate just refreshes the local SWR data
          updateCustomFields: (updatedListings) =>
            refreshSurveyBuilding({
              ...surveyBuilding,
              survey_listings: surveyBuilding.survey_listings.map((sl) => ({
                ...sl,
                listing: updatedListings.find((l) => l.id === sl.listing.id),
              })),
            }),
          parentObjectIds: spaceIds,
          surveyId,
          type: 'listing',
        }}
      />
      {SpaceFieldMenuComponent}
      {DeleteSpaceFieldModalComponent}
      {ConfirmChangeFieldTypeModalComponent}
    </>
  )
}

const SpaceHeader = ({
  surveyBuilding,
  buildingName,
  space,
  updateSpaceName,
  deleteSurveyListing,
  isSticky,
}) => {
  const menuState = usePopupState({
    variant: 'popover',
    popupId: `space-${space.id}-menu`,
  })
  const surveyListingId = useMemo(
    () =>
      surveyBuilding.survey_listings.find((sl) => sl.listing.id === space.id)
        .id,
    [surveyBuilding, space]
  )
  const { EditSpaceMenuComponent, ConfirmRemoveSpaceFromSurveyModalComponent } =
    useEditSpaceMenu({
      menuState,
      surveyListingId,
      spaceName: space.address2,
      updateSpaceName,
      deleteSurveyListing,
      buildingName,
      withoutEditNameOption: true,
    })

  return (
    <TableHeaderCell
      classes={{
        tableCell: clsx(
          'py-0 px-[22px] border-0 border-solid border-[#E0E0E0] border-t border-r border-b',
          isSticky && 'shadow-b'
        ),
      }}
    >
      <div
        className="flex items-center cursor-pointer text-[#111111] uppercase"
        {...bindTrigger(menuState)}
      >
        <Typography variant="tableHeader">Field Value</Typography>
        {menuState.isOpen ? <TextChevronUp /> : <TextChevronDown />}
      </div>
      {EditSpaceMenuComponent}
      {ConfirmRemoveSpaceFromSurveyModalComponent}
    </TableHeaderCell>
  )
}

function CustomFieldRow({
  index,
  surveyId,
  fieldData,
  valueData,
  building,
  setFieldMenuAnchorEl,
  renameCustomField,
  uniqueFieldNames,
  createCustomFieldValue,
  updateCustomFieldValue,
  moveField,
  saveFieldOrder,
}) {
  const fieldId = fieldData ? fieldData.id : null
  const fieldType = fieldData.data_type
  const fieldName = fieldData.label
  const fieldValue = valueData ? valueData.value : null
  const IconClass = getIconByDataTypeId(fieldType)
  const surveyBuildingCustomFieldId = valueData ? valueData.id : null
  const customFieldValueId = valueData ? valueData.custom_field_value_id : null

  const openMenu = (event) =>
    setFieldMenuAnchorEl({
      anchor: event.currentTarget,
      fieldName,
      buildingName: getBuildingPrimaryName(building),
      modelFieldName: null,
      fieldId,
      fieldType,
      fieldValue,
      customFieldValueId,
    })

  const { ref, previewRef, handlerId, opacity } = useDraggableRow({
    type: 'building-field',
    index,
    onMove: moveField,
    onDrop: saveFieldOrder,
  })

  return (
    <TableRow
      ref={previewRef}
      data-handler-id={handlerId}
      data-index={index}
      style={{
        opacity,
      }}
    >
      <TableContentCell
        classes={{
          tableCell:
            'pl-2 pr-3 border-0 border-solid border-[#E0E0E0] border-l border-r border-b',
        }}
      >
        <div className="flex items-center">
          <div ref={ref} style={{ display: 'flex', paddingRight: '6px' }}>
            <DragIcon className="cursor-grab" />
          </div>
          <SquareIconButton onClick={openMenu}>
            <IconClass />
          </SquareIconButton>
          {fieldData ? (
            <EditableCell
              value={fieldName}
              updateValue={() => {}}
              updateValueApi={(newName) =>
                new Promise((resolve) => {
                  if (newName && !uniqueFieldNames.has(newName)) {
                    renameCustomField({
                      id: fieldId,
                      newName,
                    })
                    resolve(newName)
                  } else {
                    resolve()
                  }
                })
              }
              classesIn={editableCellStyles({
                isKey: true,
              })}
              styleOverrides={{ hoverBackgroundColor: '#f3f3f3' }}
              textContentVariant="bodyBold"
            />
          ) : (
            <Typography
              variant="bodyBold"
              className="ml-2 whitespace-nowrap overflow-hidden text-ellipsis"
            >
              {fieldName}
            </Typography>
          )}
        </div>
      </TableContentCell>
      <TableContentCell
        classes={{
          tableCell:
            'px-3 border-0 border-solid border-[#E0E0E0] border-r border-b',
        }}
      >
        <EditableCell
          value={fieldValue}
          updateValueApi={(newValue) =>
            new Promise((resolve) => {
              if (!customFieldValueId) {
                createCustomFieldValue({
                  surveyId,
                  buildingId: building.id,
                  customFieldId: fieldId,
                  newValue,
                })
              } else {
                updateCustomFieldValue({
                  id: surveyBuildingCustomFieldId,
                  newValue,
                })
              }
              resolve(newValue)
            })
          }
          fieldType={fieldType}
          classesIn={editableCellStyles()}
          styleOverrides={{ hoverBackgroundColor: '#f3f3f3' }}
          field={fieldData}
        />
      </TableContentCell>
    </TableRow>
  )
}

function SpaceTableRow({
  index,
  surveyId,
  label,
  field,
  values,
  surveySpaces,
  updateListing,
  createCustomFieldValue,
  updateCustomFieldValue,
  renameCustomField,
  setFieldMenuAnchorEl,
  buildingName,
  uniqueFieldNames,
  moveField,
  saveFieldOrder,
}) {
  const isReserved = ['Space Name', 'Space Notes'].includes(label)

  const { ref, previewRef, handlerId, opacity } = useDraggableRow({
    type: 'space-field',
    index,
    onMove: moveField,
    onDrop: saveFieldOrder,
  })

  const modelFieldName = isReserved
    ? getModelFieldNameByDisplayName(label)
    : null
  const isCustomField = !isReserved
  const fieldId = field.id
  const fieldType = field.data_type
  const IconClass = getIconByDataTypeId(fieldType)
  const placeholderText = getPlaceholderTextByDisplayName(label)

  const openMenu = (event) =>
    setFieldMenuAnchorEl({
      anchor: event.currentTarget,
      fieldName: label,
      buildingName,
      modelFieldName,
      fieldType,
      fieldId,
    })

  return (
    <TableRow
      ref={previewRef}
      data-handler-id={handlerId}
      style={{
        opacity,
      }}
      data-field-id={field.id}
    >
      <TableContentCell
        classes={{
          tableCell:
            'pl-2 pr-3 sticky left-8 z-[2] bg-white border-0 border-solid border-[#E0E0E0] border-l border-r border-b',
        }}
      >
        <div className="flex items-center">
          <div
            ref={isCustomField ? ref : null}
            style={{ display: 'flex', paddingRight: '6px' }}
          >
            {isCustomField ? (
              <DragIcon className="cursor-grab" />
            ) : (
              <div className="flex items-center ml-7 p-[2px]">
                <LogoIcon />
              </div>
            )}
          </div>

          {isCustomField ? (
            <>
              <SquareIconButton onClick={openMenu}>
                <IconClass />
              </SquareIconButton>
              <EditableCell
                value={label}
                updateValue={() => {}}
                updateValueApi={(newName) =>
                  new Promise((resolve) => {
                    if (newName && !uniqueFieldNames.has(newName)) {
                      renameCustomField({
                        id: fieldId,
                        newName,
                      })
                      resolve(newName)
                    } else {
                      resolve()
                    }
                  })
                }
                classesIn={editableCellStyles({ isKey: true })}
                styleOverrides={{ hoverBackgroundColor: '#f3f3f3' }}
                textContentVariant="bodyBold"
              />
            </>
          ) : (
            <Typography
              variant="bodyBold"
              className="ml-[10px] whitespace-nowrap overflow-hidden text-ellipsis"
            >
              {label}
            </Typography>
          )}
        </div>
      </TableContentCell>
      {values.map((fieldValue, vIndex) => {
        const { value } = fieldValue
        const { listing } = surveySpaces[vIndex]

        const updateValue = (newValue) => {
          const { listing } = surveySpaces[vIndex]

          if (modelFieldName) {
            // updating a regular field
            updateListing(listing.id, { [modelFieldName]: newValue })
          } else if (!fieldValue.id) {
            createCustomFieldValue({
              surveyId,
              listingId: listing.id,
              customFieldId: fieldId,
              newValue,
            })
          } else {
            // updating a custom field
            updateCustomFieldValue({
              id: fieldValue.id,
              newValue,
            })
          }
        }
        return (
          <TableContentCell
            key={`${label}:${listing.id}`}
            data-key={`${label}:${listing.id}`}
            classes={{
              tableCell:
                'py-0 px-3 border-0 border-solid border-[#E0E0E0] border-r border-b',
            }}
          >
            <EditableCell
              value={value}
              updateValue={updateValue}
              updateValueApi={(newValue) =>
                new Promise((resolve) => {
                  updateValue(newValue)
                  resolve(newValue)
                })
              }
              fieldType={fieldType}
              classesIn={editableCellStyles()}
              styleOverrides={{ hoverBackgroundColor: '#f3f3f3' }}
              placeholder={placeholderText}
              field={field}
            />
          </TableContentCell>
        )
      })}
    </TableRow>
  )
}

// FIXME: Temporary fix to allow removing MUI makeStyles calls (This is a Tailwind equivalent)
const editableCellStyles = ({ isKey = false } = {}) => ({
  container: `h-9 pl-3 cursor-pointer mr-0 ${isKey ? 'mt-[1px]' : ''}`,
  value: 'p-0',
  valueInput: 'm-0 mt-[1px]',
  valueClearInputIcon: 'text-[#e0e0e0] hover:text-[#111111]',
})

function useDraggableRow({ type, index, onMove, onDrop }) {
  const ref = useRef(null)
  const previewRef = useRef(null)

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const [{ handlerId }, drop] = useDrop({
    accept: type,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      onMove(dragIndex, hoverIndex)
      // eslint-disable-next-line
      item.index = hoverIndex
    },
    drop: () => {
      onDrop()
    },
  })

  const opacity = isDragging ? 0 : 1

  drag(ref)
  drop(previewRef)
  preview(previewRef)

  return {
    ref,
    previewRef,
    handlerId,
    isDragging,
    opacity,
  }
}

const useStyles = makeStyles({
  metadataTable: {
    minWidth: '640px',
    tableLayout: 'fixed',
    borderCollapse: 'separate',

    '& tr': {
      '& th:not(:last-child), td:not(:last-child)': {
        borderRight: '1px solid #e0e0e0',
      },

      // FIXME: This is going to apply to the building fields as well
      // But for now is not a problem since is not affecting the layout
      '& th, td': {
        width: '220px',
      },
    },

    // Table border radiuses
    '& tr:first-child th:first-child': {
      borderTopLeftRadius: '4px',
    },
    '& tr:first-child th:last-child': {
      borderTopRightRadius: '4px',
    },
    '& tr:last-child td:first-child': {
      borderBottomLeftRadius: '4px',
    },
    '& tr:last-child td:last-child': {
      borderBottomRightRadius: '4px',
    },
  },
})
