import { fetchApi } from './rootApi'
import { redirectOn401 } from './helpers'
import { formatId, SEGMENT_PARAM_NAMES } from '../utils'

const SURVEY_BUILDINGS_ENDPOINT_ROOT = 'survey_buildings'

export const surveyBuildingApi = {
  errorCodes: {
    SURVEY_BUILDING_ALREADY_EXISTS: 'SURVEY_BUILDING_ALREADY_EXISTS',
  },

  getSurveyBuilding: async ({ surveyId, buildingId, skipAuth }) => {
    const promise = fetchApi({
      endpoint: `${SURVEY_BUILDINGS_ENDPOINT_ROOT}/-1/?${new URLSearchParams({
        surveyId,
        buildingId,
      })}`,
      fetchArgs: {
        method: 'GET',
      },
      skipAuth,
    })
    promise.catch((error) => skipAuth && redirectOn401(error.response))
    return promise
  },

  getActivity: async (surveyId, buildingId) =>
    fetchApi({
      endpoint: `surveys/${surveyId}/buildings/${buildingId}/survey_building_activity/`,
      fetchArgs: {
        method: 'GET',
      },
    }),

  updatePartial: async ({ surveyBuildingId, partial, theme }) =>
    fetchApi({
      endpoint: `${SURVEY_BUILDINGS_ENDPOINT_ROOT}/${surveyBuildingId}/`,
      fetchArgs: {
        method: 'PATCH',
        body: JSON.stringify({ ...partial, colors: theme.palette.primary }),
      },
      trackingArgs: {
        name: 'Update Survey Building Partial',
        metadata: {
          [SEGMENT_PARAM_NAMES.SURVEY_BUILDING_ID]: formatId(surveyBuildingId),
        },
      },
    }),

  create: async ({
    surveyId,
    building,
    serializerKey = null,
    copySpacesFromSurveyId = null,
  }) => {
    return fetchApi({
      endpoint: `${SURVEY_BUILDINGS_ENDPOINT_ROOT}/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify({
          survey: surveyId,
          building,
          serializer_key: serializerKey,
          copy_spaces_from_survey_id: copySpacesFromSurveyId,
        }),
      },
      trackingArgs: {
        name: 'Add Building To Survey',
        metadata: {
          [SEGMENT_PARAM_NAMES.BUILDING_ID]: formatId(building.id),
          [SEGMENT_PARAM_NAMES.SURVEY_ID]: formatId(surveyId),
          [SEGMENT_PARAM_NAMES.COPY_SPACES_FROM_SURVEY_ID]: formatId(
            copySpacesFromSurveyId
          ),
        },
      },
    })
  },

  createSurveyListing: async ({ surveyBuildingId }) => {
    return fetchApi({
      endpoint: `${SURVEY_BUILDINGS_ENDPOINT_ROOT}/${surveyBuildingId}/create_survey_listing/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify({}),
      },
      trackingArgs: {
        name: 'Add New Listing To Building',
        metadata: {
          [SEGMENT_PARAM_NAMES.SURVEY_BUILDING_ID]: formatId(surveyBuildingId),
        },
      },
    })
  },

  clearSpacesField: async ({ surveyBuildingId, modelFieldName }) =>
    fetchApi({
      endpoint: `${SURVEY_BUILDINGS_ENDPOINT_ROOT}/${surveyBuildingId}/clear_spaces_field/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify({
          space_field: modelFieldName,
        }),
      },
      trackingArgs: {
        name: 'Clear Listing Custom Field',
        metadata: {
          [SEGMENT_PARAM_NAMES.SURVEY_BUILDING_ID]: formatId(surveyBuildingId),
          [SEGMENT_PARAM_NAMES.FIELD_NAME]: modelFieldName,
        },
      },
    }),

  deleteAttachment: async ({ surveyBuildingAttachmentId }) =>
    fetchApi({
      endpoint: `survey_building_attachments/${surveyBuildingAttachmentId}/`,
      fetchArgs: {
        method: 'DELETE',
      },
    }),

  copySpacesFromSurvey: async ({ surveyBuildingId, copySpacesFromSurveyId }) =>
    fetchApi({
      endpoint: `${SURVEY_BUILDINGS_ENDPOINT_ROOT}/${surveyBuildingId}/copy_spaces_from_survey/?${new URLSearchParams(
        {
          copy_spaces_from_survey_id: copySpacesFromSurveyId,
        }
      )}`,
      fetchArgs: {
        method: 'GET',
      },
    }),
}
