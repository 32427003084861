// @ts-check
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Api from 'rest-fetcher-redux'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Container, Typography, Tooltip, Fade } from '@material-ui/core'
import clsx from 'clsx'

import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import TouchBackend from 'react-dnd-touch-backend'
import {
  setSurvey,
  setSurveyListings,
  setSurveyBuildings,
} from '~/legacy/store/actions/viewSurvey'

import { getBuildingPrimaryName, copyTextToClipboard } from '~/legacy/utils'
import {
  SurveyBreadcrumb,
  SquareIconButton,
  LinkIconSvg,
  Loading,
  COMPARE_SURVEY_SPACES,
  ShareIcon,
  Modal,
  MODALS,
} from '~/legacy/components'

import CompareListings from './CompareListings'
import useCompareSurveyListingsDimensions from './CompareListingsHooks'

// TODO: We should probably do this once on init?
const isTouchDevice = () => {
  if ('ontouchstart' in window) {
    return true
  }
  return false
}
const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend

const LEFT_CONTAINER_HORIZ_PADDING_PX = 32
const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  emptyStateDimension: {
    width: '900px',
    height: '100%',
  },
  normalDimension: {
    height: 'fit-content',
    paddingLeft: 0,
    paddingRight: 0,
    width: '100%',
  },
  breadcrumb: {
    marginTop: '12px',
    display: 'flex',
    alignItems: 'center',
    minHeight: '44px',
  },
  actionButtonContainer: {
    marginLeft: 'auto',
  },
  title: {
    padding: `24px 0 ${LEFT_CONTAINER_HORIZ_PADDING_PX}px 0`,
    borderBottom: '1px solid #E0E0E0',
  },
  overlay: {
    position: 'absolute',
    top: '40%',
    left: '50%',
  },
  pageWidth: {
    width: (props) => `${props.CONTENT_WIDTH}px`,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
  },
  linkIcon: {
    marginLeft: '16px',
  },
})

const StyledToolip = withStyles({
  tooltip: (props) => ({
    color: 'white',
    backgroundColor: props.isGreen ? '#28932D' : '#808080',
  }),
  // eslint-disable-next-line
})(({ isGreen, ...props }) => <Tooltip {...props} />)

// TODO: Maybe generalize this container / header? This is essentially a "Survey Header"
const CompareSurveyListings = ({ match }) => {
  const surveyId = match.params.survey_id
    ? parseInt(match.params.survey_id, 10)
    : null
  const dispatch = useDispatch()
  const user = useSelector((s) => s.user)

  const [shareModalOpen, setShareModalOpen] = useState(false)

  // We use the same survey / survey listings as the SDP for actual SPA!
  // TODO: generalize selection / fetching for both pages
  // TODO: bug check SPA behavior of navigating to another survey
  const survey = useSelector((store) => store.pages.viewSurvey.survey)
  const surveyListings = useSelector(
    (store) => store.pages.viewSurvey.surveyListings
  )

  // Whether or not we've fetched the survey / survey listings, so we don't try twice
  const [hasFetchedSurvey, setHasFetchedSurvey] = useState(false)
  const [doneFetchingSurvey, setDoneFetchingSurvey] = useState(false)
  const [hasFetchedSurveyListings, setHasFetchedSurveyListings] =
    useState(false)
  const [doneFetchingSurveyListings, setDoneFetchingSurveyListings] =
    useState(false)
  const fetchSurvey = () => {
    setHasFetchedSurvey(true)
    Api.getProject({ id: surveyId }).then((result) => {
      dispatch(setSurvey(result))
      setDoneFetchingSurvey(true)
    })
  }
  const fetchSurveyListings = () => {
    setHasFetchedSurveyListings(true)
    return Api.getSurveyListings({ id: surveyId }).then((results) => {
      dispatch(setSurveyListings(results.survey_listings))
      dispatch(setSurveyBuildings(results.survey_buildings))
      setDoneFetchingSurveyListings(true)
    })
  }

  React.useEffect(() => {
    // If we don't have a survey, or we have the wrong survey, get the fresh one
    if (
      (!survey || !survey.id || survey.id !== surveyId) &&
      !hasFetchedSurvey
    ) {
      fetchSurvey()
    }
    // If we don't have any survey listings, or we have the wrong survey, get the fresh one
    // We shouldn't ever try to fetch survey listings for an empty survey in expected user behavior
    // TODO: should be a better way to check which survey the survey listings are from.
    if (
      (!surveyListings ||
        !surveyListings.length ||
        surveyListings[0].survey.id !== surveyId) &&
      !hasFetchedSurveyListings
    ) {
      fetchSurveyListings()
    }
  }, [survey, surveyListings])

  const [listingsOrder, setListingsOrder] = useState([])

  const queryParams = new URLSearchParams(useLocation().search)

  // Get a set of listing ids from the url param
  const listingIds = queryParams.getAll('listings') || []
  const listingIdsSet = new Set(listingIds.map((s) => parseInt(s, 10)))
  // The set of listing ids to display, initially from the url param
  const [requestedListingIdsSet, setRequestedListingIdsSet] =
    useState(listingIdsSet)

  // All of the listings in the survey
  const [allListingsInSurvey, setAllListingsInSurvey] = useState([])
  // Listings to show, taken from the survey listings and filtered by url param listings
  const [listings, setListings] = useState([])

  const { CONTENT_WIDTH } = useCompareSurveyListingsDimensions(listings.length)
  const classes = useStyles({ CONTENT_WIDTH })

  // Get a set of listing ids from the url param
  const initialFieldOrdering = queryParams.getAll('listingFields') || []
  const [listingFieldsUrlParam, setListingFieldsUrlParam] =
    useState(initialFieldOrdering)

  // Whether or not to hide building fields, initially whatever is in url param
  const hideBuildingFieldsString = queryParams.get('hideBuildingFields')
  const [hideBuildingProperties, setHideBuildingProperties] = useState(
    hideBuildingFieldsString ? !!parseInt(hideBuildingFieldsString, 10) : null
  )

  // The list of fields to hide on the page
  // format: `{0, 1}_{field_name}`
  const initialHiddenListingFields =
    queryParams.getAll('hiddenListingFields') || []
  const [hiddenListingFieldsUrlParam, setHiddenListingFieldsUrlParam] =
    useState(initialHiddenListingFields)

  // Set the URL of the page as the parameters of the comparison change
  useEffect(() => {
    const urlParams = []
    // Add the listings in order
    listingsOrder.forEach((listing) => {
      urlParams.push(`listings=${listing.id}`)
    })

    // Add the ordered listing fields
    if (listingFieldsUrlParam) {
      listingFieldsUrlParam.forEach((listingField) => {
        urlParams.push(`listingFields=${listingField}`)
      })
    }

    // Hidden fields
    if (hiddenListingFieldsUrlParam) {
      hiddenListingFieldsUrlParam.forEach((listingField) => {
        urlParams.push(`hiddenListingFields=${listingField}`)
      })
    }

    // Whether or not to hide building fields
    if (hideBuildingProperties !== null) {
      urlParams.push(`hideBuildingFields=${hideBuildingProperties ? 1 : 0}`)
    }

    window.history.replaceState(null, null, `?${urlParams.join('&')}`)
  }, [
    listingsOrder,
    listingFieldsUrlParam,
    hiddenListingFieldsUrlParam,
    hideBuildingProperties,
  ])

  React.useEffect(() => {
    // Pluck the desired compare listings from the survey listings
    if (Array.isArray(surveyListings)) {
      setAllListingsInSurvey(
        surveyListings.map((surveyListing) => surveyListing.listing)
      )
      setListings(
        surveyListings
          .filter((surveyListing) =>
            requestedListingIdsSet.has(surveyListing.listing.id)
          )
          .map((surveyListing) => surveyListing.listing)
      )
    }
  }, [surveyListings, requestedListingIdsSet])

  // Whether or not all of the requested listings belong to the same building
  const [building, setBuilding] = useState(null)
  // Check if all of the listings are part of the same building
  useEffect(() => {
    if (listings && listings.length > 0) {
      if (new Set([...listings.map((l) => l.building.id)]).size === 1) {
        setBuilding(listings[0].building)
      }
    } else {
      setBuilding(null)
    }
  }, [listings])

  const [hoverCopyLink, setHoverCopyLink] = useState(false)

  // Whether or not we've loaded everything enough to show the page
  const isPageReady =
    !!survey && !!survey.id && !!surveyListings && !!surveyListings.length

  const [copyToastOpen, setCopyToastOpen] = React.useState(false)
  const [hoverStateSelected, setHoverStateSelected] = useState(true)
  useEffect(() => {
    if (copyToastOpen) {
      setHoverStateSelected(false)
    } else if (hoverCopyLink) {
      setHoverStateSelected(true)
    }
  }, [copyToastOpen, hoverCopyLink])

  if (!isPageReady && doneFetchingSurvey && doneFetchingSurveyListings) {
    return 'Invalid survey, please compare listings in another survey'
  }

  // Survey / survey listings still loading
  if (!isPageReady) {
    return (
      <Loading
        isLoading
        size={40}
        className={clsx(classes.overlay, classes.loading)}
      />
    )
  }

  const noListingsToCompare = isPageReady && (!listings || !listings.length)

  return (
    <Container
      maxWidth={false}
      className={clsx(
        classes.root,
        noListingsToCompare
          ? classes.emptyStateDimension
          : classes.normalDimension
      )}
    >
      <div className={clsx(classes.breadcrumb, classes.pageWidth)}>
        <SurveyBreadcrumb
          surveyId={survey.id}
          surveyName={survey.name}
          pageType={COMPARE_SURVEY_SPACES}
        />
        <div className={classes.actionButtonContainer}>
          {!user.isAnonymous && (
            <Tooltip title="Share Comparison">
              <SquareIconButton>
                <ShareIcon onClick={() => setShareModalOpen(true)} />
              </SquareIconButton>
            </Tooltip>
          )}
          <StyledToolip
            open={copyToastOpen || hoverCopyLink}
            title={hoverStateSelected ? 'Copy Link' : 'Link Copied'}
            TransitionComponent={Fade}
            isGreen={!hoverStateSelected}
            className={classes.linkIcon}
          >
            <SquareIconButton
              onMouseEnter={() => setHoverCopyLink(true)}
              onMouseLeave={() => setHoverCopyLink(false)}
            >
              <LinkIconSvg
                onClick={() => {
                  if (!copyToastOpen) {
                    setCopyToastOpen(true)
                    setTimeout(() => {
                      setCopyToastOpen(false)
                    }, 3000)
                  }
                  copyTextToClipboard(window.location.href, true)
                }}
              />
            </SquareIconButton>
          </StyledToolip>
        </div>
      </div>
      <div className={clsx(classes.title, classes.pageWidth)}>
        <Typography variant="h1">
          {building ? getBuildingPrimaryName(building) : 'Compare Spaces'}
        </Typography>
      </div>
      <DndProvider backend={backendForDND}>
        <CompareListings
          isPageReady={isPageReady}
          survey={survey}
          listings={listings}
          allListingsInSurvey={allListingsInSurvey}
          building={building}
          requestedListingIdsSet={requestedListingIdsSet}
          setRequestedListingIdsSet={setRequestedListingIdsSet}
          setListingsOrder={setListingsOrder}
          setListingFieldsUrlParam={setListingFieldsUrlParam}
          setHiddenListingFieldsUrlParam={setHiddenListingFieldsUrlParam}
          setHideBuildingProperties={setHideBuildingProperties}
          listingsOrder={listingsOrder}
          initialFieldOrdering={initialFieldOrdering}
          initialHiddenListingFields={initialHiddenListingFields}
          hideBuildingProperties={hideBuildingProperties}
        />
      </DndProvider>
      <Modal
        content={MODALS.COMPARISON_SHARE_MODAL}
        onClose={() => setShareModalOpen(false)}
        open={shareModalOpen}
        childProps={{
          survey,
        }}
      />
    </Container>
  )
}

export default CompareSurveyListings
