// @ts-check
import React, { useMemo } from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { useLocation, useHistory } from 'react-router'
import { isBroker, IsPreviewContext } from '~/legacy/utils'
import { Link, Typography } from '~/legacy/components'
import SpaceCard from '~/legacy/components/SpaceCard'
import { useSurveyListingCustomFieldSelector } from '~/legacy/utils/hooks/useSurveyListingCustomFieldSelector'
import { useSurveyCustomFieldsSelector } from '~/legacy/utils/hooks/useSurveyCustomFieldsSelector'
import { formatCustomFieldsForDisplayProperties } from '~/legacy/utils/listingHelpers'

const useStyles = makeStyles({
  titleSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  spaces: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'column',
  },
  spaceCard: {
    '&:not(:last-child)': {
      marginBottom: '16px',
    },
  },
})

export default function SpacesSection({
  className,
  surveySpaces,
  surveyId,
  surveyBuilding,
  updateListing,
  createSurveyListing,
}) {
  const classes = useStyles()
  const isUserBroker = useSelector((s) => isBroker(s.user))
  const { isPreview } = React.useContext(IsPreviewContext)
  const spaces = surveySpaces.map((sl) => sl.listing)

  const history = useHistory()
  const location = useLocation()

  const openModal = () => {
    history.replace({
      pathname: location.pathname,
      search: `?edit=Spaces`,
    })
  }

  const { customFieldValues } = useSurveyListingCustomFieldSelector({
    surveyId,
    buildingId: surveyBuilding.building.id,
  })

  const { mutate: refetch, fieldMap } = useSurveyCustomFieldsSelector({
    surveyId,
    enabled: Boolean(surveyId),
  })

  // FIXME: This is probably a temporary fix. At some point we should handle the custom field definitions
  // and the custom field values in the same hook ???
  const customFieldValuesForDisplay = useMemo(() => {
    return formatCustomFieldsForDisplayProperties(customFieldValues, fieldMap)
  }, [fieldMap, customFieldValues])

  const valuesBySpaceId = _.groupBy(
    customFieldValuesForDisplay,
    'original.listing_id'
  )

  return (
    <div className={className}>
      {spaces.length ? (
        <>
          <div className={classes.titleSection}>
            <Typography variant="pageSubTitle">Spaces</Typography>
            {!!isUserBroker && !isPreview && (
              <Link onClick={openModal}>
                <Typography variant="boldText">Add/Edit Spaces</Typography>
              </Link>
            )}
          </div>
          <div className={classes.spaces}>
            {spaces.map((space) => (
              <SpaceCard
                key={space.id}
                className={classes.spaceCard}
                space={space}
                updateListing={updateListing}
                values={valuesBySpaceId[space.id] || []}
              />
            ))}
          </div>
        </>
      ) : (
        <SpaceCard
          className={classes.spaceCard}
          createSurveyListing={async () => {
            openModal()
            await createSurveyListing({ surveyBuildingId: surveyBuilding.id })
            refetch()
          }}
          isPlaceholder
        />
      )}
    </div>
  )
}
