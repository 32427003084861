import { useTheme } from '@material-ui/core/styles'
import useSWR from 'swr'
import { v4 as uuidv4 } from 'uuid'
import {
  buildingApi,
  listingApi,
  surveyBuildingApi,
  surveyListingApi,
} from '~/legacy/fetchApi'
import { uploadFiles } from '~/legacy/utils/fileHelpers'
import { getExtension } from '~/legacy/utils/miscUtils'
import { SnackbarUtils } from '~/legacy/utils'
import { defaultMutateBinder, defaultMutateOptimisticBinder } from './selectors'

// Fetch fresh bdp data from our backend
const fetchSurveyBuilding = async (surveyId, buildingId, isAnonymous) => {
  return surveyBuildingApi
    .getSurveyBuilding({ surveyId, buildingId, skipAuth: isAnonymous })
    .then(([, responseSurveyBuilding]) => {
      return responseSurveyBuilding
    })
}

// Our bdp data in SWR
export const SWR_SURVEY_BUILDING = 'surveys/:survey_id/buildings/:building_id'
const DEFAULT_API_ERROR_MESSAGE =
  'Error updating survey building, please try again.'

// TODO: Make the selector a singleton if we use it in multiple places, so we don't have to redo
//   local state reformatting multiple times
// Hook to manage the raw Survey Building in SWR as well as format the raw Survey Building.
export const useSurveyBuildingSelector = (
  surveyId,
  buildingId = null,
  isAnonymous = false
) => {
  const theme = useTheme()
  // Async wrapper for fetching our bdp data from backend
  const swrArgs = []
  if (surveyId && buildingId) {
    swrArgs.push(async () =>
      fetchSurveyBuilding(surveyId, buildingId, isAnonymous)
    )
  }

  // SWR bdp data
  const {
    data: swrSurveyBuilding,
    error,
    mutate,
  } = useSWR(
    SWR_SURVEY_BUILDING.replace(':survey_id', surveyId).replace(
      ':building_id',
      buildingId
    ),
    ...swrArgs
  )

  const safeSurveyBuilding = swrSurveyBuilding || {}

  // Bind our mutators with our default settings and error handling
  const mutateSurveyBuilding = defaultMutateBinder(
    mutate,
    DEFAULT_API_ERROR_MESSAGE
  )
  const mutateSurveyBuildingOptimistic = defaultMutateOptimisticBinder(
    mutateSurveyBuilding,
    DEFAULT_API_ERROR_MESSAGE
  )

  // Update the project via api and then mutate the project state. Optionally update the local state with optimistic data before the API call
  // TODO: Further generalize this? Let's start with this for now
  const mutateUpdate = async (newSurveyBuildingPartial, optimistic = true) => {
    const apiMutator = async (rawSurveyBuildingLocal) =>
      surveyBuildingApi
        .updatePartial({
          surveyBuildingId: rawSurveyBuildingLocal.id,
          partial: newSurveyBuildingPartial,
          theme,
        })
        .then(([, responseSurveyBuilding]) => responseSurveyBuilding)

    if (optimistic) {
      return mutateSurveyBuildingOptimistic({
        newObject: { ...safeSurveyBuilding, ...newSurveyBuildingPartial },
        mutator: apiMutator,
      })
    }
    return mutateSurveyBuilding({ mutator: apiMutator })
  }

  const mutateUpdateBuilding = async (
    newBuildingPartial,
    optimistic = true
  ) => {
    const apiMutator = async (rawSurveyBuildingLocal) =>
      buildingApi
        .updatePartial({
          buildingId: rawSurveyBuildingLocal.building.id,
          partial: newBuildingPartial,
          theme,
        })
        .then(([, responseBuilding]) => ({
          ...rawSurveyBuildingLocal,
          building: {
            ...rawSurveyBuildingLocal.building,
            ...responseBuilding.data,
          },
        }))

    if (optimistic) {
      return mutateSurveyBuildingOptimistic({
        newObject: {
          ...safeSurveyBuilding,
          building: { ...safeSurveyBuilding.building, ...newBuildingPartial },
        },
        mutator: apiMutator,
      })
    }
    return mutateSurveyBuilding({ mutator: apiMutator })
  }

  const mutateAddSurveyBuildingAttachments = async (
    attachments,
    optimistic = true
  ) => {
    // Spoof attachment models that mimic our API resonse so that the optmistic works and we can show the attachments before officially uploaded
    const newAttachments = attachments.map((attachment) => ({
      name: attachment.name,
      // Spoof the ID, but give them a unique UUID (tempId)
      id: -1,
      tempId: uuidv4(),
      ext: getExtension(attachments[0].name),
      extension: getExtension(attachments[0].name),
      uuid: attachment.uuid,
      rawFile: attachment,
    }))

    const apiMutator = async (rawSurveyBuildingLocal) => {
      // Remove the temporary previews we inserted at the end for the optimistic
      const newTempIds = new Set(
        attachments.map((attachment) => attachment.tempId)
      )
      const currentAttachments = rawSurveyBuildingLocal.attachments.filter(
        (attachment) => newTempIds.has(attachment.tempId)
      )

      return uploadFiles(attachments, 'survey_building_attachments', {
        survey_building_id: rawSurveyBuildingLocal.id,
      })
        .then((resp) =>
          // Add the new attachments
          ({
            ...rawSurveyBuildingLocal,
            attachments: [...currentAttachments, ...resp.data.file],
          })
        )
        .catch(() => {
          // Error, go back to the original attachments
          SnackbarUtils.error('Error uploading attachment, please try again')
          return { ...rawSurveyBuildingLocal, attachments: currentAttachments }
        })
    }

    if (optimistic) {
      return mutateSurveyBuildingOptimistic({
        newObject: {
          ...safeSurveyBuilding,
          attachments: [...safeSurveyBuilding.attachments, ...newAttachments],
        },
        mutator: apiMutator,
      })
    }
    return mutateSurveyBuilding({ mutator: apiMutator })
  }

  const mutateDeleteSurveyBuildingAttachment = async (
    attachmentId,
    optimistic = true
  ) => {
    const apiMutator = async (rawSurveyBuildingLocal) => {
      return surveyBuildingApi
        .deleteAttachment({ surveyBuildingAttachmentId: attachmentId })
        .then(() => ({
          ...rawSurveyBuildingLocal,
          attachments: [
            ...rawSurveyBuildingLocal.attachments.filter(
              (attachment) => attachment.id !== attachmentId
            ),
          ],
        }))
    }

    if (optimistic) {
      return mutateSurveyBuildingOptimistic({
        newObject: {
          ...safeSurveyBuilding,
          attachments: [
            ...safeSurveyBuilding.attachments.filter(
              (attachment) => attachment.id !== attachmentId
            ),
          ],
        },
        mutator: apiMutator,
      })
    }
    return mutateSurveyBuilding({ mutator: apiMutator })
  }

  const mutateDeleteSurveyListing = async (
    surveyListingId,
    optimistic = true
  ) => {
    const apiMutator = async (rawSurveyBuildingLocal) =>
      surveyListingApi.deleteSurveyListing({ surveyListingId }).then(() => ({
        ...rawSurveyBuildingLocal,
        survey_listings: rawSurveyBuildingLocal.survey_listings.filter(
          (sl) => sl.id !== surveyListingId
        ),
      }))

    if (optimistic) {
      return mutateSurveyBuildingOptimistic({
        newObject: {
          ...safeSurveyBuilding,
          survey_listings: safeSurveyBuilding.survey_listings.filter(
            (sl) => sl.id !== surveyListingId
          ),
        },
        mutator: apiMutator,
      })
    }
    return mutateSurveyBuilding({ mutator: apiMutator })
  }

  const mutateUpdateListing = async (
    listingId,
    newListingPartial,
    optimistic = true
  ) => {
    const incorporateUpdatedListing = (_surveyListings, updatedListing) =>
      _surveyListings.map((sl) => {
        if (sl.listing.id === updatedListing.id) {
          return {
            ...sl,
            listing: {
              ...sl.listing,
              ...updatedListing,
            },
          }
        }
        return { ...sl }
      })

    const apiMutator = async (rawSurveyBuildingLocal) =>
      listingApi
        .updatePartial({
          listingId,
          partial: newListingPartial,
        })
        .then(([, responseListing]) => ({
          ...rawSurveyBuildingLocal,
          survey_listings: incorporateUpdatedListing(
            rawSurveyBuildingLocal.survey_listings,
            responseListing
          ),
        }))

    if (optimistic) {
      return mutateSurveyBuildingOptimistic({
        newObject: {
          ...safeSurveyBuilding,
          survey_listings: incorporateUpdatedListing(
            safeSurveyBuilding.survey_listings,
            { id: listingId, ...newListingPartial }
          ),
        },
        mutator: apiMutator,
      })
    }
    return mutateSurveyBuilding({ mutator: apiMutator })
  }

  const mutateCreateSurveyListing = async ({ surveyBuildingId }) => {
    const apiMutator = async () =>
      surveyBuildingApi
        .createSurveyListing({
          surveyBuildingId,
        })
        .then(([, responseSurveyBuilding]) => responseSurveyBuilding.data)

    return mutateSurveyBuilding({ mutator: apiMutator })
  }

  const mutateClearSpacesField = async (modelFieldName, optimistic = true) => {
    const clearSpaceField = (_surveyListings) =>
      _surveyListings.map((sl) => {
        return {
          ...sl,
          listing: {
            ...sl.listing,
            [modelFieldName]: null,
          },
        }
      })
    const apiMutator = async (rawSurveyBuildingLocal) =>
      surveyBuildingApi
        .clearSpacesField({
          surveyBuildingId: rawSurveyBuildingLocal.id,
          modelFieldName,
        })
        .then(() => ({
          ...rawSurveyBuildingLocal,
          survey_listings: clearSpaceField(
            rawSurveyBuildingLocal.survey_listings
          ),
        }))

    if (optimistic) {
      return mutateSurveyBuildingOptimistic({
        newObject: {
          ...safeSurveyBuilding,
          survey_listings: clearSpaceField(safeSurveyBuilding.survey_listings),
        },
        mutator: apiMutator,
      })
    }
    return mutateSurveyBuilding({ mutator: apiMutator })
  }

  const mutateCopySpacesFromSurvey = async ({ copySpacesFromSurveyId }) => {
    const apiMutator = async () =>
      surveyBuildingApi
        .copySpacesFromSurvey({
          surveyBuildingId: safeSurveyBuilding.id,
          copySpacesFromSurveyId,
        })
        .then(([, response]) => response)

    return mutateSurveyBuilding({ mutator: apiMutator })
  }

  return {
    surveyBuilding: swrSurveyBuilding,
    swrSurveyBuilding,
    mutate,
    mutateUpdate,
    mutateUpdateBuilding,
    mutateDeleteSurveyListing,
    mutateUpdateListing,
    mutateAddSurveyBuildingAttachments,
    mutateDeleteSurveyBuildingAttachment,
    mutateClearSpacesField,
    mutateCreateSurveyListing,
    mutateCopySpacesFromSurvey,
    loading: !swrSurveyBuilding,
    error,
  }
}
