// @ts-check
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import {
  BulkImportCsvReader,
  Button,
  DocumentIcon,
  AISparkIcon,
} from '~/legacy/components'
import { Typography } from '~/legacy/components/themeComponents'
import { isBroker, BULK_IMPORT_HELPERS } from '~/legacy/utils'
import FileExtractorInput from '~/legacy/components/FileExtractorInput'

const useStyles = makeStyles({
  emptyContainer: {
    height: 'calc(100% - 32px)',
    color: '#666666',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F3F3F3',
    borderRadius: '4px',
  },
  emptySections: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '264px',
    height: '200px',
  },
  column: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    '&:first-child': {
      borderRight: '1px solid #D9D9D9',
    },
  },
  emptyContainerText: {
    marginTop: '6px',
  },
  importListingsTitle: {
    marginTop: '8px',
  },
  uploadButton: {
    textTransform: 'none !important',
  },
})

const ImportListingsSection = ({
  setBackdropOpen,
  setLoadedListingsFromCsv,
  setRawCsvHeaders,
  setBulkImportFieldMatchModalOpen,
  setUploadedCsvFile,
}) => {
  const { id: surveyId } = useParams()
  const classes = useStyles()
  const linkRef = React.useRef(null)
  const labelRef = React.useRef(null)
  const user = useSelector((s) => s.user)

  return isBroker(user) ? (
    <div className={classes.emptyContainer}>
      <div className={classes.column}>
        <div className={classes.emptySections}>
          <DocumentIcon />
          <Typography
            className={classes.importListingsTitle}
            variant="emptyStateHeader"
          >
            Import data from CSV
          </Typography>
          <Typography className={classes.emptyContainerText} variant="bodyText">
            Easily add multiple buildings and spaces at once using a CSV.
          </Typography>

          {/* Button for the CSV reader... only works with link labels for whatever reason */}
          <Button
            color="primary"
            variant="text"
            className={classes.uploadButton}
            onClick={() => {
              linkRef.current.click()
            }}
          >
            Upload a CSV
          </Button>
          <BulkImportCsvReader
            linkRef={linkRef}
            setBackdropOpen={setBackdropOpen}
            setUploadedCsvFile={setUploadedCsvFile}
            setLoadedListingsFromCsv={setLoadedListingsFromCsv}
            setRawCsvHeaders={setRawCsvHeaders}
            setBulkImportFieldMatchModalOpen={setBulkImportFieldMatchModalOpen}
          />
        </div>
      </div>
      <div className={classes.column}>
        <div className={classes.emptySections}>
          <AISparkIcon />
          <Typography
            className={classes.importListingsTitle}
            variant="emptyStateHeader"
          >
            Import data from PDF
          </Typography>
          <Typography className={classes.emptyContainerText} variant="bodyText">
            Use AI to import building and space data from a PDF or image.
          </Typography>

          <Button
            color="primary"
            variant="text"
            className={classes.uploadButton}
            onClick={() => {
              labelRef.current.click()
            }}
          >
            Upload a PDF or image
          </Button>

          {/* 
            FIXME: Doing the label click hack because I'dont want to spend much time
            making the label itself look like the button.
          */}
          <label ref={labelRef}>
            <FileExtractorInput
              onExtracted={(results) => {
                BULK_IMPORT_HELPERS.onCsvFileLoaded(
                  results,
                  setLoadedListingsFromCsv,
                  setRawCsvHeaders,
                  setBulkImportFieldMatchModalOpen
                )
              }}
              setParentLoading={setBackdropOpen}
              surveyId={surveyId}
              setUploadedCsvFile={setUploadedCsvFile}
            />
          </label>
        </div>
      </div>
    </div>
  ) : null
}

export default ImportListingsSection
